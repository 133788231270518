import React, { useContext, useEffect, useState } from "react";
import {
    BasicButton,
    BoldText,
    ContentContainer,
    DeleteButton,
    DetailsGrid2Col,
    EditButton,
    FlexRow,
    PageContainer,
    PageContentContainer,
    PageHeader,
    ProfilePic,
    SpaceBetweenRow,
} from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import { InputWithSearch } from "../../commons/InputWithSearch";
import {
    addCourseUpdate,
    checkAdminPassword,
    deletePayment,
    deleteStudent,
    fetchLockingDate,
    fetchStudent,
    makePayment,
    toggleCourseOver,
    updateInstruction,
    updatePayment,
    updateStudent,
} from "../../utils/FirebaseUtil";
import moment from "moment";
import FeesTableInProfile from "../../commons/FeesTableInProfile";
import CourseTableInProfile from "../../commons/CourseTableInProfile";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteRounded, EditRounded } from "@mui/icons-material";
import {
    batchTimingNumberToString,
    coursesList,
    getBalance,
    modalStyles,
    modeOfPayment,
    topicList,
    valid,
} from "../../utils/constants";
import Modal from "react-modal";
import CustomInput from "../../commons/CustomInput";
import { v4 as uuidv4 } from "uuid";
import { updateDoc } from "firebase/firestore/lite";
import UserContext from "../../commons/context";
import styled from "styled-components";
import { esAR } from "rsuite/esm/locales";

const ActionBar = styled.div`
    display: flex;
`;

const StudentSearch = () => {
    const params = useParams();
    const { reg } = params;
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);
    const [regNum, setRegNum] = useState("");
    const [stud, setStud] = useState();
    const [isAddFeeModalOpen, setIsAddFeeModalOpen] = useState(false);
    const [isAddCourseUpdateModalOpen, setIsAddCourseUpdateModalOpen] =
        useState(false);
    const [isUpdateInstructionModalOpen, setIsUpdateInstructionModalOpen] =
        useState(false);
    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
        useState(false);
    const [newCourseUpdate, setNewCourseUpdate] = useState("");
    const [newCourseDate, setNewCourseDate] = useState();
    const [feeInfo, setFeeInfo] = useState({
        date: "",
        mopIndex: "0",
        money: "",
        receiptNumber: "",
    });
    const [newInstruct, setNewInstruct] = useState("");
    const [adminPassConfirm, setAdminPassConfirm] = useState("");
    const [isExamDateModalOpen, setIsExamDateModalOpen] = useState(false);

    const [editFeeInfo, setEditFeeInfo] = useState({
        date: "",
        mopIndex: "0",
        money: "",
        receiptNumber: "",
    });

    useEffect(() => {
        if (reg) {
            searchStudentDetails(reg);
            setRegNum(reg);
        }
    }, [reg]);

    const searchStudentDetails = async (r) => {
        const student = await fetchStudent(r);
        setStud(student);
        if (!student) {
            alert("Student not found!");
        } else {
            setNewInstruct(student.instruct);
        }
    };

    const onRegNumSearch = () => {
        navigate(`/studentSearch/${regNum}`, { replace: true });
    };

    const updateRegNum = (newRegNum) => {
        setRegNum(newRegNum);
    };

    const navigateToEditPage = () => {
        if (regNum != "") {
            navigate(`/editStudent/${regNum}`);
        }
    };

    const handleDeleteStudent = async () => {
        if (regNum !== "") {
            const res = await checkAdminPassword(adminPassConfirm);
            const { passHash } = res;
            if (passHash === "") {
                alert("Wrong Password");
                return;
            }

            const isDeleted = await deleteStudent(stud.regNum);
            if (isDeleted) {
                alert("Deleted!");
                navigate(-1);
            } else {
                alert("Error!");
            }
        }
    };

    const openAddFeeModal = () => {
        setIsAddFeeModalOpen(true);
    };

    const closeAddFeeModal = () => {
        setIsAddFeeModalOpen(false);
    };

    const openAddCourseUpdateModal = () => {
        setIsAddCourseUpdateModalOpen(true);
    };

    const closeAddCourseUpdateFeeModal = () => {
        setIsAddCourseUpdateModalOpen(false);
    };

    const openUpdateInstructionModal = () => {
        setIsUpdateInstructionModalOpen(true);
    };

    const closeUpdateInstructionModal = () => {
        setIsUpdateInstructionModalOpen(false);
    };

    const openDeleteConfirmModal = () => {
        if (regNum !== "") {
            setIsDeleteConfirmModalOpen(true);
        }
    };

    const closeDeleteConfirmModal = () => {
        setIsDeleteConfirmModalOpen(false);
    };

    const openExamDateModal = () => {
        setIsExamDateModalOpen(true);
    }

    const closeExamDateModal = () => {
        setIsExamDateModalOpen(false);
        searchStudentDetails(reg);
    }

    const handleSetExamDate = async () => {
        const isUpdateDone = await updateStudent(stud);
        if(isUpdateDone){
            alert("Exam Date set!");
            closeExamDateModal();
        }else{
            alert("Error!");
        }
    }

    const handleExamDateRemove = async () => {
        let isAllPercentEmpty = true;
        stud.course.map((c) => {
            if(valid(c.percent)){
                isAllPercentEmpty = false;
            }
        });
        if(!isAllPercentEmpty){
            alert("Percentage should be empty for all courses!");
            return;
        }
        const isUpdateDone = await updateStudent({...stud, examDate: ""});
        if(isUpdateDone){
            alert("Exam Date Removed!");
            closeExamDateModal();
            searchStudentDetails(reg);
        }else{
            alert("Error!");
        }
    }

    const handleAddFee = async () => {
        const feeData = feeInfo;
        const lockingDate = await fetchLockingDate();
        if (moment(feeData.date).isBefore(moment(lockingDate))) {
            alert("Fee Receipt too old to add!");
            return;
        }
        feeData["uuid"] = uuidv4();

        // const modeOfPayIndex = modeOfPayment.indexOf(feeData.mode);
        // feeData['mopIndex'] = `${modeOfPayIndex}`;
        // delete feeData.mode;
        const isPaymentDone = await makePayment(
            feeData,
            stud.branch,
            stud.regNum,
            getBalance(stud.totalFees, stud.fees)
        );
        if (isPaymentDone) {
            alert("Done!");
            searchStudentDetails(reg);
            closeAddFeeModal();
        } else {
            alert("Error!");
        }
    };

    const handleCourseUpdate = async () => {
        const isCourseUpdateDone = await addCourseUpdate(
            stud.regNum,
            newCourseUpdate,
            newCourseDate
        );
        if (isCourseUpdateDone) {
            alert("Added!");
            closeAddCourseUpdateFeeModal();
            searchStudentDetails(stud.regNum);
        } else {
            alert("Error!");
        }
    };

    const handleInstructionUpdate = async () => {
        const isInstrcutionUpdateDone = await updateInstruction(
            stud.regNum,
            newInstruct
        );
        if (isInstrcutionUpdateDone) {
            alert("Updated!");
            closeUpdateInstructionModal();
            searchStudentDetails(stud.regNum);
        } else {
            alert("Error!");
        }
    };

    const handleToggleCourseOver = async () => {
        await toggleCourseOver(stud.regNum);
        searchStudentDetails(stud.regNum);
    };

    return (
        <PageContainer>
            <Modal
                isOpen={isAddFeeModalOpen}
                onRequestClose={closeAddFeeModal}
                style={modalStyles}
            >
                <PageHeader>Add Fee</PageHeader>
                <CustomInput
                    label={"Receipt Number"}
                    type="number"
                    value={feeInfo.receiptNumber}
                    onChangeValue={(v) => {
                        setFeeInfo({ ...feeInfo, receiptNumber: v });
                    }}
                />
                <CustomInput
                    label={"Date of Payment"}
                    type="date"
                    value={feeInfo.date}
                    onChangeValue={(v) => {
                        setFeeInfo({ ...feeInfo, date: v });
                    }}
                />
                <CustomInput
                    label={"Amount"}
                    type="number"
                    value={feeInfo.money}
                    onChangeValue={(v) => {
                        setFeeInfo({ ...feeInfo, money: v });
                    }}
                    placeholder="₹"
                />
                <CustomInput
                    label={"Mode of Payment"}
                    type="dropdown"
                    value={modeOfPayment[parseInt(feeInfo.mopIndex)]}
                    onChangeValue={(v) => {
                        setFeeInfo({
                            ...feeInfo,
                            mopIndex: modeOfPayment.indexOf(v),
                        });
                    }}
                    menuItems={modeOfPayment}
                />
                <BasicButton onClick={handleAddFee}>Save</BasicButton>
            </Modal>
            <Modal
                isOpen={isAddCourseUpdateModalOpen}
                onRequestClose={closeAddCourseUpdateFeeModal}
                style={modalStyles}
            >
                <PageHeader>Add Course Update</PageHeader>
                <CustomInput
                    label={"Course"}
                    type="dropdown"
                    value={newCourseUpdate}
                    onChangeValue={(v) => {
                        setNewCourseUpdate(v);
                    }}
                    menuItems={topicList}
                />
                <CustomInput
                    label={"Date"}
                    type="date"
                    value={newCourseDate}
                    onChangeValue={(v) => {
                        setNewCourseDate(v);
                    }}
                />
                <BasicButton onClick={handleCourseUpdate}>Add</BasicButton>
            </Modal>
            <Modal
                isOpen={isUpdateInstructionModalOpen}
                onRequestClose={closeUpdateInstructionModal}
                style={modalStyles}
            >
                <PageHeader>Instruction</PageHeader>
                <CustomInput
                    label={"Instruction"}
                    type="text"
                    value={newInstruct}
                    onChangeValue={(v) => {
                        setNewInstruct(v);
                    }}
                    placeholder="New Instruction"
                />
                <BasicButton onClick={handleInstructionUpdate}>
                    Update
                </BasicButton>
            </Modal>
            <Modal
                isOpen={isDeleteConfirmModalOpen}
                onRequestClose={closeDeleteConfirmModal}
                style={modalStyles}
            >
                <PageHeader>Delete Student?</PageHeader>
                <span>Are you sure you want to delete {regNum}?</span>
                <CustomInput
                    label={"Admin Password"}
                    type="password"
                    value={adminPassConfirm}
                    onChangeValue={(v) => {
                        setAdminPassConfirm(v);
                    }}
                    placeholder="**"
                />
                <BasicButton onClick={handleDeleteStudent}>Delete</BasicButton>
            </Modal>
            <Modal
                isOpen={isExamDateModalOpen}
                onRequestClose={closeExamDateModal}
                style={modalStyles}
            >
                <PageHeader>Set Exam Date for {stud && stud.regNum}</PageHeader>
                <CustomInput
                    label={"Date"}
                    type="date"
                    value={stud && stud.examDate}
                    onChangeValue={(v) => {
                        setStud({...stud, examDate: v});
                    }}
                />
                <FlexRow>
                    <BasicButton onClick={handleExamDateRemove}>Remove</BasicButton>
                    <SpaceBetweenRow />
                    <BasicButton onClick={handleSetExamDate}>Save</BasicButton>
                </FlexRow>
            </Modal>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Student Search</PageHeader>
                    <SpaceBetweenRow>
                        <InputWithSearch
                            value={regNum}
                            onValueChange={updateRegNum}
                            placeholder={"Reg Num"}
                            onSearchClick={onRegNumSearch}
                            extras={{ maxLength: "5" }}
                        />
                        <ActionBar>
                            <EditButton onClick={navigateToEditPage}>
                                <EditRounded
                                    style={{ marginRight: "0.4rem" }}
                                />
                                Edit
                            </EditButton>
                            {(user.branch === "" ||
                                user.branch == undefined) && (
                                <DeleteButton onClick={openDeleteConfirmModal}>
                                    <DeleteRounded
                                        style={{ marginRight: "0.4rem" }}
                                    />
                                    Delete
                                </DeleteButton>
                            )}
                        </ActionBar>
                    </SpaceBetweenRow>
                    {stud && (
                        <>
                            <ProfilePic
                                onClick={() => {
                                    window.open(stud.photoUrl, "_blank");
                                }}
                                src={
                                    stud.photoUrl !== ""
                                        ? stud.photoUrl
                                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                                }
                            />
                            <ProfilePic
                                onClick={() => {
                                    window.open(stud.signUrl, "_blank");
                                }}
                                src={
                                    stud.signUrl !== ""
                                        ? stud.signUrl
                                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                                }
                            />
                            <DetailsGrid2Col>
                                <div>Registration Number: </div>
                                <BoldText>{stud.regNum}</BoldText>
                                <div>Date of Join: </div>
                                <BoldText>
                                    {moment(stud.doj).format("DD/MM/YYYY")}
                                </BoldText>
                                <div>Branch: </div>
                                <BoldText>{stud.branch}</BoldText>
                                <div>Courses: </div>
                                <BoldText>
                                    {stud.course
                                        .map((c) => c.courseName)
                                        .join(", ")}
                                </BoldText>
                                <div>Batch: </div>
                                <BoldText>
                                    {batchTimingNumberToString(
                                        stud.batchTiming
                                    )}
                                </BoldText>
                                <div>Full Name: </div>
                                <BoldText>{stud.name}</BoldText>
                                <div>Age: </div>
                                <BoldText>
                                    {moment().diff(moment(stud.dob), "years")}
                                </BoldText>
                                <div>Gender: </div>
                                <BoldText>{stud.gender}</BoldText>
                                <div>Qualification: </div>
                                <BoldText>{stud.qualification}</BoldText>
                                <div>Address: </div>
                                <BoldText>{stud.address}</BoldText>
                                <div>Contact Number: </div>
                                <a href={`tel:${stud.mobileNumber}`}>
                                    <BoldText>{stud.mobileNumber}</BoldText>
                                </a>
                                <div>Parent's Number: </div>
                                <a href={`tel:${stud.parentMobileNumber}`}>
                                    <BoldText>
                                        {stud.parentMobileNumber}
                                    </BoldText>
                                </a>
                                <div>Course Fees: </div>
                                <BoldText>₹{stud.totalFees}/-</BoldText>
                                <div>Installment: </div>
                                <BoldText>{stud.installment}</BoldText>
                                <div>
                                    <div>Fees History: </div>
                                </div>
                                <BoldText>
                                    <FeesTableInProfile
                                        fees={stud.fees}
                                        totalFees={stud.totalFees}
                                        onAddFeeButton={openAddFeeModal}
                                    />
                                </BoldText>
                                <div>Course Updates: </div>
                                <BoldText>
                                    <CourseTableInProfile
                                        courses={stud.courseUpdates}
                                        onAddCourseUpdate={
                                            openAddCourseUpdateModal
                                        }
                                    />
                                </BoldText>
                                <div>Instructions: </div>
                                <BoldText>
                                    {stud.instruct}
                                    <BasicButton
                                        onClick={openUpdateInstructionModal}
                                    >
                                        Update Instruction
                                    </BasicButton>
                                </BoldText>
                                <div>Exam Date: </div>
                                <BoldText>
                                    {valid(stud.examDate) ? moment(stud.examDate).format("DD/MM/YYYY") : "---"}
                                    <BasicButton
                                        onClick={openExamDateModal}
                                    >
                                        Update Exam Date
                                    </BasicButton>
                                </BoldText>
                                <div>Certificate Print Date: </div>
                                <BoldText>
                                    {stud.course
                                        .map((c) => c.printDate)
                                        .filter((v) => valid(v))
                                        .map((d) =>
                                            moment(d).format("DD/MM/YYYY")
                                        )
                                        .join(", ")}
                                </BoldText>
                                <div>Course Over: </div>
                                <BasicButton onClick={handleToggleCourseOver}>
                                    {stud.over && stud.over === "true"
                                        ? "Set Not Course Over"
                                        : "Set Course Over"}
                                </BasicButton>
                            </DetailsGrid2Col>
                        </>
                    )}
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
};

export default StudentSearch;
