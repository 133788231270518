import React, { useContext, useEffect } from "react";
import { checkIfPassHashCorrect, checkIfPassHashCorrectForAdmin } from "../utils/FirebaseUtil";
import UserContext from "./context";
import { useNavigate } from "react-router-dom";

const FacultyProtectedRoute = ({children}) => {

    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    const handleUserUpdate = async () => {
        const isFacultyAuth = await checkIfPassHashCorrect(user.pass, user.branch);
        const isAdminAuth = await checkIfPassHashCorrectForAdmin(user.pass);
        if (!isFacultyAuth && !isAdminAuth) {
            navigate("/");
        }
    };

    useEffect(() => {
        if (user.pass !== undefined) {
            handleUserUpdate();
        }
    }, [user]);

    return <>
    {children}
    </>;
}

export default FacultyProtectedRoute;