import moment from "moment";
import React from "react";
import styled from "styled-components";
import { ClickableCell, TableCell, TableHeader } from "./styles";
import { useNavigate } from "react-router-dom";

const TableGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border-radius: 8px;
    overflow: hidden;
`;

const AdmissionTable = ({students}) => {
    const navigate = useNavigate();
    return (
        <>
            <TableGrid>
                <TableHeader>Reg. Num</TableHeader>
                <TableHeader>DoJ</TableHeader>
                <TableHeader>Course</TableHeader>
                <TableHeader>Total Fees</TableHeader>
                <TableHeader>Paid</TableHeader>
                {
                    students.map((stud, index) => {
                        return <>
                            <ClickableCell><TableCell onClick={() => {
                                navigate(`/studentSearch/${stud.regNum}`);
                            }}>{stud.regNum}</TableCell></ClickableCell>
                            <TableCell>{moment(stud.doj).format("DD/MM/YYYY")}</TableCell>
                            <TableCell>{stud.course.map((c) => c.courseName).join(", ")}</TableCell>
                            <TableCell>{stud.totalFees}</TableCell>
                            <TableCell>{stud.feesPaid}</TableCell>
                        </>
                    })
                }
                <TableHeader>Total</TableHeader>
                <TableHeader></TableHeader>
                <TableHeader></TableHeader>
                <TableHeader>
                    {students.reduce((total, stud) => {
                        return total + parseInt(stud.totalFees);
                    }, 0)}
                </TableHeader>
                <TableHeader>
                    {students.reduce((total, stud) => {
                        return total + parseInt(stud.feesPaid);
                    }, 0)}
                </TableHeader>
            </TableGrid>
        </>
    );
}

export default AdmissionTable;