import React, { useContext, useEffect, useState } from "react";
import { BasicButton, ContentContainer, PageContainer, PageContentContainer, PageHeader } from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import CustomInput from "../../commons/CustomInput";
import { batchTimingStringToNumber, batchTimings, branches, valid } from "../../utils/constants";
import { fetchStudentsByBatchTiming, updateStudent } from "../../utils/FirebaseUtil";
import UserContext from "../../commons/context";
import BatchTable from "../../commons/BatchTable";
import moment from "moment";
import { modalStyles } from "../../utils/constants";
import Modal from "react-modal";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

const BatchwiseReport = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [batchTiming, setBatchTiming] = useState("");
    const [branch, setBranch] = useState("Poisar");
    const [students, setStudents] = useState([]);
    const {user, setUser} = useContext(UserContext);
    const [selectedStud, setSelectedStud] = useState({});
    const [isExamDateModalOpen, setIsExamDateModalOpen] = useState(false);
    const [isRemoveExamDateModalOpen, setIsRemoveExamDateModalOpen] = useState(false);

    useEffect(() => {
        console.log("params updated");
        const batchTimingP = searchParams.get("batchTiming");
        const branchP = searchParams.get("branch");
        if(batchTimingP === null){return;}
        setBatchTiming(batchTimingP);
        setBranch(branchP);
        handleBatchTimingSearch(batchTimingP, branchP);
    }, [searchParams]);

    useEffect(() => {
        if(batchTiming !== ""){
            const params = {batchTiming, branch};
            navigate({pathname: '/batchwiseReport', search: `?${createSearchParams(params)}`}, {replace: true});
        }
    }, [batchTiming, branch]);

    const findPaidThisMonth = (stud) => {
        let paidThisMonth = 0;
        const today = moment();
        stud.fees.map((fee) => {
            const feeDate = moment(fee.date);
            if(feeDate.month() === today.month() && feeDate.year() === today.year()){
                paidThisMonth += parseFloat(fee.money);
            }
        });
        return paidThisMonth;
    }

    const findOpBal = (stud) => {
        let paidBefore = 0;
        const today = moment().date(1);
        stud.fees.map((fee) => {
            const feeDate = moment(fee.date);
            if(feeDate.isBefore(today)){
                paidBefore += parseFloat(fee.money);
            }
        });

        return parseFloat(stud.totalFees) - paidBefore;
    }

    const handleBatchTimingSearch = async (batchTimingP, branchP) => {
        console.log("searching");
        const res = await fetchStudentsByBatchTiming(batchTimingStringToNumber(batchTimingP), user.branch ? user.branch : branchP);
        const notOverRes = res.filter((stud) => {
            return stud.over !== "true"
        }).map((stud) => {
            return {...stud, paidThisMonth: findPaidThisMonth(stud), opBal: findOpBal(stud)};
        });
        setStudents([...notOverRes]);
    }

    const handleSetExamDate = async () => {
        console.log(selectedStud);
        const isUpdateDone = await updateStudent(selectedStud);
        if(isUpdateDone){
            alert("Exam Date set!");
            closeExamDateModal();
            handleBatchTimingSearch(batchTiming, branch);
        }else{
            alert("Error!");
        }
    }

    const handleExamDateRemove = async () => {
        let isAllPercentEmpty = true;
        selectedStud.course.map((c) => {
            if(valid(c.percent)){
                isAllPercentEmpty = false;
            }
        });
        if(!isAllPercentEmpty){
            alert("Percentage should be empty for all courses!");
            return;
        }
        const isUpdateDone = await updateStudent({...selectedStud, examDate: ""});
        if(isUpdateDone){
            alert("Exam Date Removed!");
            closeRemoveExamDateModal();
            handleBatchTimingSearch(batchTiming, branch);
        }else{
            alert("Error!");
        }
    }

    const openExamDateModal = (selStud) => {
        setSelectedStud(selStud);
        setIsExamDateModalOpen(true);
    }

    const closeExamDateModal = () => {
        setIsExamDateModalOpen(false);
        setSelectedStud({});
    }

    const openRemoveExamDateModal = (selStud) => {
        setSelectedStud(selStud);
        setIsRemoveExamDateModalOpen(true);
    }

    const closeRemoveExamDateModal = () => {
        setIsRemoveExamDateModalOpen(false);
        setSelectedStud({});
    }

    return (
        <PageContainer>
            <Modal
                isOpen={isExamDateModalOpen}
                onRequestClose={closeExamDateModal}
                style={modalStyles}
            >
                <PageHeader>Set Exam Date for {selectedStud.regNum}</PageHeader>
                <CustomInput
                    label={"Date"}
                    type="date"
                    value={selectedStud.examDate}
                    onChangeValue={(v) => {
                        setSelectedStud({...selectedStud, examDate: v});
                    }}
                />
                <BasicButton onClick={handleSetExamDate}>Set</BasicButton>
            </Modal>
            <Modal
                isOpen={isRemoveExamDateModalOpen}
                onRequestClose={closeRemoveExamDateModal}
                style={modalStyles}
            >
                <PageHeader>Remove Exam Date for {selectedStud.regNum}</PageHeader>
                <BasicButton onClick={handleExamDateRemove}>Remove</BasicButton>
            </Modal>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Batchwise Report {students.length !== 0 && `(${students.length})`}</PageHeader>
                    <CustomInput label={"Batch Timing"} type="dropdown" value={batchTiming} onChangeValue={(v) => {
                        setBatchTiming(v);
                    }} menuItems={batchTimings}/>
                    {
                        (user.branch === "" || user.branch == undefined) && <CustomInput label={"Branch"} type="dropdown" value={branch} onChangeValue={(v) => {
                            setBranch(v);
                        }} menuItems={branches}/>
                    }
                    <BatchTable students={students} handleExamDateClick={openExamDateModal} handleExamDateRemove={openRemoveExamDateModal}/>
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
}

export default BatchwiseReport;