import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./ViewInquiry.module.css";
import moment from "moment/moment";
import {
    BasicButton,
    ContentContainer,
    PageContainer,
    PageContentContainer,
    PageHeader,
} from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import CustomInput from "../../commons/CustomInput";
import { branchesWithAll, expensesFilter } from "../../utils/constants";
import { fetchInquiriesByDate, submitInquiry } from "../../utils/FirebaseUtil";
import styled from "styled-components";
import { TableCell } from "../../commons/styles";
import { TableHeader } from "../../commons/styles";
import { CheckCircle, RadioButtonUncheckedRounded } from "@mui/icons-material";
import { valid } from "../../utils/constants";
import Modal from "react-modal";

const InqListContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const InquiryCard = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1rem;
    background-color: ${props => props.isDone ? "#b2f8cb" : "aliceblue"};
    margin-bottom: 0.5rem;
    border-radius: 6px;
    overflow: hidden;
`;

const InquiryCardContent = styled.div`
    flex: 1;
    display: grid;
    grid-template-columns: auto 1fr;
    border-radius: 6px;
    overflow: hidden;
`;

const MarkAsDoneBtn = styled.div`
    margin-left: 1rem;
    color: #427eff;
    cursor: pointer;
`;

export const modalStyles = {
    content: {
        width: "90%",
        maxWidth: "600px",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        borderRadius: "1rem",
        border: "0",
        backgroundImage: "linear-gradient(to bottom, #ffffff, #f4f4f4)",
    },
    overlay: {
        background: "#000000a6",

        zIndex: 1000,
    },
};

const ViewInquiry = () => {
    const [allinqList, setAllInqList] = useState([]);
    const [inqList, setInqList] = useState([]);
    const [selectedInq, setSelectedInq] = useState({});
    const [dateRange, setDateRange] = useState();
    const [branch, setBranch] = useState("Poisar");
    const [filter, setFilter] = useState("All");
    const [isMarkDoneModalOpen, setIsMarkDoneModalOpen] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const startDateP = searchParams.get("startDate");
        const endDateP = searchParams.get("endDate");
        const branchP = searchParams.get("branch");
        if(startDateP === null){return;}
        const dateRangeP = [new Date(startDateP), new Date(endDateP)];
        setDateRange(dateRangeP);
        setBranch(branchP);
        viewInquiry(dateRangeP, branchP);
    }, [searchParams]);

    useEffect(() => {
        if(!dateRange || dateRange.length === 0){return;}
        const params = {startDate: dateRange[0], endDate: dateRange[1], branch: branch};
        navigate({pathname: '/viewInquiry', search: `?${createSearchParams(params)}`}, {replace: true});
        // viewInquiry();
    }, [dateRange, branch]);

    useEffect(() => {
        filterExpenses();
    }, [filter, allinqList]);

    const viewInquiry = async (dateRangeP, branchP) => {
        if(!dateRangeP){return;}
        const startDate = moment(dateRangeP[0]).format("yyyy-MM-DD");
        const endDate = moment(dateRangeP[1]).format("yyyy-MM-DD");
        let inqs = await fetchInquiriesByDate(startDate, endDate, branchP);
        setAllInqList([...inqs]);
    };

    const handleMarkDoneInquiry = async () => {
        if(valid(selectedInq.regNum) && valid(selectedInq.doj)){
            const isUpdated = await submitInquiry(selectedInq);
            if(isUpdated){
                alert("Admission Done!");
                viewInquiry(dateRange, branch);
                closeMarkDoneModal();
            }else{
                alert("Error!");
            }
        }else{
            alert("Fill details first!");
        }
    }

    const filterExpenses = () => {
        if(filter === "All"){
            setInqList(allinqList);
        }else if(filter === "Pending"){
            setInqList(allinqList.filter((inq) => !inq.regNum));
        }else{
            setInqList(allinqList.filter((inq) => inq.regNum));
        }
    }

    const openMarkDoneModal = (inqData) => {
        setSelectedInq(inqData);
        setIsMarkDoneModalOpen(true);
    }

    const closeMarkDoneModal = () => {
        setIsMarkDoneModalOpen(false);
    }   

    return (
        <PageContainer>
            <Modal
                isOpen={isMarkDoneModalOpen}
                onRequestClose={closeMarkDoneModal}
                style={modalStyles}
            >
                <PageHeader>Admission Done?</PageHeader>
                <CustomInput
                    label={"Reg Num"}
                    type="number"
                    value={selectedInq.regNum}
                    onChangeValue={(v) => {
                        setSelectedInq({ ...selectedInq, regNum: v });
                    }}
                />
                <CustomInput
                    label={"Date of Join"}
                    type="date"
                    value={selectedInq.doj}
                    onChangeValue={(v) => {
                        setSelectedInq({ ...selectedInq, doj: v });
                    }}
                />
                <BasicButton onClick={handleMarkDoneInquiry}>Save</BasicButton>
            </Modal>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Inquiry Report ({inqList.length})</PageHeader>
                    <CustomInput label={"Date Range"} type="dateRange" value={dateRange} onChangeValue={(v) => {
                        setDateRange(v);
                    }}/>
                    <CustomInput label={"Branch"} type="dropdown" value={branch} onChangeValue={(v) => {
                        setBranch(v);
                    }} menuItems={branchesWithAll}/>
                    <CustomInput label={"Filter"} type="dropdown" value={filter} onChangeValue={(v) => {
                        setFilter(v);
                    }} menuItems={expensesFilter}/>
                    <InqListContainer>
                        {inqList.map((inq) => {
                            return (
                                <InquiryCard isDone={inq.regNum !== undefined}>
                                    <InquiryCardContent>
                                        <TableHeader>Name</TableHeader>
                                        <TableCell>{inq.studentName}</TableCell>
                                        <TableHeader>Phone Num</TableHeader>
                                        <TableCell>
                                            <a href={`tel:${inq.phoneNum}`}>
                                                {inq.phoneNum}
                                            </a>
                                        </TableCell>
                                        <TableHeader>Course</TableHeader>
                                        <TableCell>{inq.course}</TableCell>
                                        <TableHeader>Address</TableHeader>
                                        <TableCell>{inq.address}</TableCell>
                                        <TableHeader>Date of Inquiry</TableHeader>
                                        <TableCell>{moment(inq.date).format("DD/MM/YYYY")}</TableCell>
                                        <TableHeader>Inquiry By</TableHeader>
                                        <TableCell>{inq.inqBy ? inq.inqBy : "-"}</TableCell>
                                        {
                                            inq.regNum && <>
                                                <TableHeader>Reg Num</TableHeader>
                                                <TableCell>{inq.regNum}</TableCell>
                                                <TableHeader>Date of Join</TableHeader>
                                                <TableCell>{moment(inq.doj).format("DD/MM/YYYY")}</TableCell>
                                            </>
                                        }
                                        
                                    </InquiryCardContent>
                                    {
                                        !inq.regNum ? <MarkAsDoneBtn onClick={() => {
                                            openMarkDoneModal(inq);
                                        }}>
                                            <RadioButtonUncheckedRounded />
                                        </MarkAsDoneBtn> :
                                        <CheckCircle />
                                    }
                                    
                                </InquiryCard>
                            );
                        })}
                    </InqListContainer>
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
};

export default ViewInquiry;

// Faculty Name, Date,
