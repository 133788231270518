import React, { useContext, useEffect, useState } from "react";
import { ContentContainer, PageContainer, PageContentContainer, PageHeader } from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import CustomInput from "../../commons/CustomInput";
import { batchTimingStringToNumber, batchTimings, branches, branchesWithAll } from "../../utils/constants";
import { fetchDirectorSalary, fetchFeesByDateofJoin, fetchStudentByDateofJoin, fetchStudentsByBatchTiming } from "../../utils/FirebaseUtil";
import UserContext from "../../commons/context";
import BatchTable from "../../commons/BatchTable";
import moment from "moment";
import 'rsuite/dist/rsuite-no-reset.min.css';
import AdmissionTable from "../../commons/AdmissionTable";
import FeeCollectionTable from "../../commons/FeeCollectionTable";
import DirectorSalaryTable from "../../commons/DirectorSalaryTable";

const DirectorSalary = () => {

    const [dateRange, setDateRange] = useState();
    const [salaryList, setSalaryList] = useState([]);
    const {user, setUser} = useContext(UserContext);

    useEffect(() => {
        updateSalary();
    }, [dateRange]);

    const updateSalary = async () => {
        const startDate = moment(dateRange[0]).format("yyyy-MM-DD");
        const endDate = moment(dateRange[1]).format("yyyy-MM-DD");
        let salaries = await fetchDirectorSalary(startDate, endDate);
        setSalaryList([...salaries]);
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Director Salary</PageHeader>
                    <CustomInput label={"Date Range"} type="dateRange" value={dateRange} onChangeValue={(v) => {
                        setDateRange(v);
                    }}/>
                    <DirectorSalaryTable salaryList={salaryList}/>
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
}

export default DirectorSalary;