import moment from "moment";
import React, { useContext } from "react";
import styled from "styled-components";
import { ClickableCell, TableCell, TableHeader } from "./styles";
import { useNavigate } from "react-router-dom";
import MiniInput from "./MiniInput";
import { CheckCircleRounded, DownloadRounded, RadioButtonUncheckedRounded } from "@mui/icons-material";
import { valid } from "../utils/constants";
import UserContext from "./context";

const TableGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    border-radius: 8px;
    overflow: hidden;
`;

const DownloadBtn = styled.div`
    cursor: pointer;
    color: #427eff;
`;

const ExamTable = ({students, onStudentPercentChange, handleSingleDownload, handlePrinted}) => {
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    return (
        <>
            <TableGrid>
                <TableHeader>Reg. Num</TableHeader>
                <TableHeader>Course</TableHeader>
                <TableHeader>Exam Date</TableHeader>
                <TableHeader>Percent</TableHeader>
                <TableHeader>Download</TableHeader>
                <TableHeader>Printed?</TableHeader>
                {
                    students.map((stud, index) => {
                        return stud.course.map((studCourse, i) => {
                            return !["MSCIT", "CCC", "Typing-6Months"].includes(studCourse.courseName) ? <>
                                <ClickableCell><TableCell onClick={() => {
                                    navigate(`/studentSearch/${stud.regNum}`);
                                }}>{stud.regNum}</TableCell></ClickableCell>
                                <TableCell>{studCourse.courseName}</TableCell>
                                <TableCell>{moment(stud.examDate).format("DD/MM/YY")}</TableCell>
                                <TableCell>
                                    <MiniInput type="number" placeholder="%" value={studCourse.percent} onChangeValue={(v) => {
                                        onStudentPercentChange(index, i, v);
                                    }} extras={{min: "0", max: "100"}}/>
                                </TableCell>
                                <TableCell>
                                    <DownloadBtn onClick={() => {
                                        handleSingleDownload(stud.regNum, `cert-${stud.regNum}-${studCourse.courseName}`);
                                    }}>
                                        <DownloadRounded />
                                    </DownloadBtn>
                                </TableCell>
                                <TableCell>
                                    {
                                        !valid(studCourse.printDate) ? <DownloadBtn onClick={() => {
                                            handlePrinted(stud.regNum, studCourse.courseName, true);
                                        }}>
                                            <RadioButtonUncheckedRounded />
                                        </DownloadBtn> : 
                                        (user.branch === "" ||
                                            user.branch == undefined) ? (
                                            <DownloadBtn onClick={() => {
                                                handlePrinted(stud.regNum, studCourse.courseName, false);
                                            }}>
                                                <CheckCircleRounded/>
                                            </DownloadBtn>
                                        ) : 
                                        <CheckCircleRounded />
                                    }
                                    
                                </TableCell>
                            </> : <></>
                        });
                    })
                }
            </TableGrid>
        </>
    );
}

export default ExamTable;