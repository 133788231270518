import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { ClickableCell, TableCell, TableHeader } from "./styles";
import { useNavigate } from "react-router-dom";

const TableGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border-radius: 8px;
    overflow: hidden;
`;

const FeeTotal = styled.div`
    font-size: 1.3rem;
    font-weight: bold;
    width: 100%;
    text-align: right;
`;

const FeeCollectionTable = ({branchName, feeList}) => {
    const navigate = useNavigate();

    return (
        <>
            {branchName}
            <TableGrid>
                <TableHeader>Reg. Num</TableHeader>
                <TableHeader>Op Bal</TableHeader>
                <TableHeader>Paid Amt</TableHeader>
                <TableHeader>Recept No</TableHeader>
                <TableHeader>Date</TableHeader>
                {
                    feeList.map((fee, index) => {
                        return <>
                            <ClickableCell><TableCell onClick={() => {
                                navigate(`/studentSearch/${fee.regNum}`)
                            }}>{fee.regNum}</TableCell></ClickableCell>
                            <TableCell>{fee.openingBal}</TableCell>
                            <TableCell>{fee.money}</TableCell>
                            <ClickableCell> <TableCell onClick={() => {
                                navigate(`/editFee/${fee.uuid}`)
                            }}>{fee.receiptNumber}</TableCell></ClickableCell>
                            <TableCell>{moment(fee.date).format("DD/MM/YYYY")}</TableCell>
                        </>
                    })
                }
            </TableGrid>
            <FeeTotal>Total: ₹{feeList.reduce((total, fee) => {
                return total + parseInt(fee.money);
            }, 0)}/-</FeeTotal>
        </>
    );
}

export default FeeCollectionTable;