import { CloseRounded } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import styled from "styled-components";

const CustomInputContainer = styled.div`
    background-color: white;
    /* color: white; */
    border-radius: 6px;
    border: none;
    outline: none;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    width: 100px;
    flex-direction: column;
    display: flex;

    @media (max-width: 600px) {
        width: 50px;
        padding: 0.5rem;
        font-size: 0.5rem;
    }
`;

const InputLabelText = styled.span`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.6rem;
    color: black;
`;

const InputField = styled.input`
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.1rem;
    color: black;

    @media (max-width: 600px) {
        font-size: 0.9rem;
    }
`;

const SelectBubble = styled.div`
    padding: 0.2rem 1rem;
    border-radius: 50vh;
    background-color: #ffffff;
    color: black;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SelectBubbleClose = styled.div`
    cursor: pointer;
`;

const MiniInput = ({
    label,
    type = "text",
    value,
    onChangeValue,
    placeholder = "Type here",
    readOnly = false,
    extras = {},
    menuItems = [],
}) => {
    const [localValue, setLocalValue] = useState("");

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    return (
        <CustomInputContainer>
            {type === "dropdown" ? (
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        {label}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={localValue}
                        label={label}
                        onChange={(e) => {
                            onChangeValue(e.target.value);
                        }}
                    >
                        {menuItems.map((item, index) => (
                            <MenuItem key={index} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : type === "image" ? (
                <input
                    type="file"
                    onChange={(e) => {
                        onChangeValue(e.target.files[0]);
                    }}
                    {...extras}
                />
            ) : type === "dateRange" ? (
                <DateRangePicker
                    onChange={(e) => {
                        onChangeValue(e);
                        console.log(e);
                    }}
                    format="dd-MM-yyyy"
                />
            ) : (
                <InputField
                    type={type}
                    value={value}
                    onChange={(e) => {
                        onChangeValue(e.target.value);
                    }}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    {...extras}
                />
            )}
        </CustomInputContainer>
    );
};

export const MultiSelectDropdownCustomInput = ({
    label,
    values,
    setValues,
    menuItems = [],
}) => {
    const onAdd = (v) => {
        const selectedCourses = values;
        if (!selectedCourses.includes(v)) {
            selectedCourses.push({
                courseName: v,
                doj: moment().format("YYYY-MM-DD"),
            });
        }
        setValues([...selectedCourses]);
    };

    const onRemove = (v) => {
        const selectedCourses = values.filter(
            (course) => course.courseName !== v
        );
        setValues([...selectedCourses]);
    };

    const onDateChange = (newDate, v) => {
        const selectedCourses = values.map((course) => {
            if (course.courseName === v) {
                return { courseName: v, doj: newDate };
            } else {
                return course;
            }
        });
        setValues([...selectedCourses]);
    };

    return (
        <CustomInputContainer>
            <InputLabelText>{label}</InputLabelText>
            {values.map((value) => {
                return (
                    <SelectBubble>
                        {value.courseName}
                        <input
                            type="date"
                            value={value.doj}
                            onChange={(e) => {
                                onDateChange(e.target.value, value.courseName);
                            }}
                        />
                        <SelectBubbleClose
                            onClick={() => {
                                onRemove(value.courseName);
                            }}
                        >
                            <CloseRounded />
                        </SelectBubbleClose>
                    </SelectBubble>
                );
            })}
            {
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        {label}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={label}
                        value={""}
                        onChange={(e) => {
                            onAdd(e.target.value);
                        }}
                    >
                        {menuItems.map((item, index) => (
                            <MenuItem key={index} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
        </CustomInputContainer>
    );
};

export default MiniInput;
