import React from "react";
import styles from "./Courses.module.css";
import img12 from "../../assets/gallery/12.jpg";
import img13 from "../../assets/gallery/13.jpg";
import img14 from "../../assets/gallery/14.jpg";
import img15 from "../../assets/gallery/15.jpg";

const Courses = () => {
    return (
        <div className={styles.coursesPageContainer}>
            <div className={styles.navbarContainer}>
                Star Computer Point Plus
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.galleryGrid}>
                    <img src={img12} />
                    <img src={img13} />
                    <img src={img14} />
                    <img src={img15} />
                </div>
            </div>
        </div>
    );
}

export default Courses;