import moment from "moment";
import React from "react";
import styled from "styled-components";
import { ClickableCell, TableCell, TableHeader } from "./styles";
import { useNavigate } from "react-router-dom";
import { CheckCircleRounded, RadioButtonCheckedRounded, RadioButtonUncheckedRounded } from "@mui/icons-material";

const TableGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    border-radius: 8px;
    overflow: hidden;
`;

const ExamTickBtn = styled.div`
    cursor: pointer;
`;

const BatchTable = ({ students, handleExamDateClick, handleExamDateRemove }) => {
    const navigate = useNavigate();

    const openStudentSearchPage = (regNum) => {
        navigate(`/studentSearch/${regNum}`);
    };

    return (
        <>
            <TableGrid>
                <TableHeader>Reg. Num</TableHeader>
                <TableHeader>DoJ</TableHeader>
                <TableHeader>Course</TableHeader>
                <TableHeader>Op Bal</TableHeader>
                <TableHeader>Paid this month</TableHeader>
                <TableHeader>Exam</TableHeader>
                {students.map((stud, index) => {
                    return (
                        <>
                            <ClickableCell><TableCell
                                onClick={() => {
                                    openStudentSearchPage(stud.regNum);
                                }}
                            >
                                {stud.regNum}
                            </TableCell></ClickableCell>
                            <TableCell>
                                {moment(stud.doj).format("DD/MM/YY")}
                            </TableCell>
                            <TableCell>
                                {stud.course
                                    .map((c) => c.courseName)
                                    .join(", ")}
                            </TableCell>
                            <TableCell>{stud.opBal}</TableCell>
                            <TableCell>{stud.paidThisMonth}</TableCell>
                            <TableCell>{stud.examDate && stud.examDate !== "" ? 
                                <ExamTickBtn onClick={() => {
                                    handleExamDateRemove(stud);
                                }}>
                                    <CheckCircleRounded />
                                </ExamTickBtn> :
                                <ExamTickBtn onClick={() => {
                                    handleExamDateClick(stud);
                                }}>
                                    <RadioButtonUncheckedRounded />
                                </ExamTickBtn>
                            }</TableCell>
                        </>
                    );
                })}
                <TableHeader>Total</TableHeader>
                <TableHeader></TableHeader>
                <TableHeader></TableHeader>
                <TableHeader></TableHeader>
                <TableHeader>
                    {students.reduce((total, stud) => {
                        return total + parseInt(stud.paidThisMonth);
                    }, 0)}
                </TableHeader>
                <TableHeader></TableHeader>
            </TableGrid>
        </>
    );
};

export default BatchTable;
