import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { ClickableCell, TableCell, TableHeader } from "./styles";
import { useNavigate } from "react-router-dom";
import { branches, categoryOfExpense } from "../utils/constants";

const TableGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 8px;
    overflow: hidden;
`;

const FeeTotal = styled.div`
    font-size: 1.3rem;
    font-weight: bold;
    width: 100%;
    text-align: right;
`;

const DirectorSalaryTable = ({salaryList}) => {
    const navigate = useNavigate();

    const getTotalByBranchAndCategory = (branch, category) => {
        return salaryList.filter((salary) => salary.branch === branch && salary.category === category).reduce((total, fee) => {
            return total + parseInt(fee.amount);
        }, 0);
    }

    const getTotalByCategory = (category) => {
        return salaryList.filter((salary) => salary.category === category).reduce((total, fee) => {
            return total + parseInt(fee.amount);
        }, 0);
    }



    return (
        <>
            <TableGrid>
                <TableHeader>Branch</TableHeader>
                <TableHeader>R</TableHeader>
                <TableHeader>S</TableHeader>
                {
                    branches.map((branch, index) => {
                        return <>
                            <TableHeader>{branch}</TableHeader>
                            <TableCell>₹{getTotalByBranchAndCategory(branch, categoryOfExpense[1])}/-</TableCell>
                            <TableCell>₹{getTotalByBranchAndCategory(branch, categoryOfExpense[2])}/-</TableCell>
                        </>
                    })
                }
                <TableHeader>Total</TableHeader>
                <TableHeader>₹{getTotalByCategory(categoryOfExpense[1])}/-</TableHeader>
                <TableHeader>₹{getTotalByCategory(categoryOfExpense[2])}/-</TableHeader>
            </TableGrid>
        </>
    );
}

export default DirectorSalaryTable;