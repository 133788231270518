import React from "react";
import styles from "./Home.module.css";
import inq from "../../assets/inq.png";
import cou from "../../assets/cou.png";
import adm from "../../assets/adm.png";
import gallery from "../../assets/gallery.jpg";
import faculty from "../../assets/faculty.png";
import noticeb from "../../assets/noticeb.png";
import exam from "../../assets/exam.png";
import banner from "../../assets/banner.png";
import logobanner from "../../assets/logobanner.png";
import bglight from "../../assets/bglight.png";
import { useNavigate } from "react-router-dom";
import {
    CardGrid3Col,
    ContentContainer,
    FeatureCard,
    FeatureCardIcon,
    FeatureCardImg,
    PageContainer,
    PageContentContainer,
} from "../../commons/styles";

const Home = () => {
    const navigate = useNavigate();

    return (
        <PageContainer>
            <div className={styles.heroContainer}>
                <img className={styles.logoBanner} src={logobanner} />
            </div>
            <div className={styles.marqueeBanner}>
                <marquee behavior="scroll">
                    <img src={banner} alt=""     />
                </marquee>
            </div>
            <PageContentContainer>
                <ContentContainer>
                    <CardGrid3Col>
                        <FeatureCard
                            onClick={() => {
                                navigate("/inquiry");
                            }}
                        >
                            <FeatureCardImg src={inq} />
                            INQUIRY
                        </FeatureCard>
                        <FeatureCard
                            onClick={() => {
                                navigate("/courses");
                            }}
                        >
                            <FeatureCardImg src={cou} />
                            COURSES
                        </FeatureCard>
                        <FeatureCard
                            onClick={() => {
                                navigate("/admission");
                            }}
                        >
                            <FeatureCardImg src={adm} />
                            ADMISSION
                        </FeatureCard>
                        <FeatureCard
                            onClick={() => {
                                navigate("/gallery");
                            }}
                        >
                            <FeatureCardImg src={gallery} />
                            GALLERY
                        </FeatureCard>
                        <FeatureCard
                            onClick={() => {
                                navigate("/noticeBoard");
                            }}
                        >
                            <FeatureCardImg src={noticeb} />
                            NOTICE BOARD
                        </FeatureCard>
                        <FeatureCard
                            onClick={() => {
                                window.open("https://www.bit.ly/scppexamapp", "_blank");
                            }}
                        >
                            <FeatureCardImg src={exam} />
                            EXAM APP
                        </FeatureCard>
                        <FeatureCard
                            onClick={() => {
                                navigate("/faculty");
                            }}
                        >
                            <FeatureCardImg src={faculty} />
                            DASHBOARD
                        </FeatureCard>
                        {/* <FeatureCard
                            onClick={() => {
                                navigate("/admin");
                            }}
                        >
                            <FeatureCardImg src={admin} />
                            ADMIN
                        </FeatureCard> */}
                    </CardGrid3Col>
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
};

export default Home;
