import React, { useContext, useEffect, useState } from "react";
import { BasicButton, ContentContainer, PageContainer, PageContentContainer, PageHeader } from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import CustomInput from "../../commons/CustomInput";
import { batchTimingStringToNumber, batchTimings, branches, branchesWithAll } from "../../utils/constants";
import { fetchFeesByDateofJoin, fetchStudentByDateofJoin, fetchStudentsByBatchTiming } from "../../utils/FirebaseUtil";
import UserContext from "../../commons/context";
import BatchTable from "../../commons/BatchTable";
import moment from "moment";
import 'rsuite/dist/rsuite-no-reset.min.css';
import AdmissionTable from "../../commons/AdmissionTable";
import FeeCollectionTable from "../../commons/FeeCollectionTable";
import { utils, writeFile } from "xlsx";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

const FeeCollectionReport = () => {

    const [dateRange, setDateRange] = useState();
    const [branch, setBranch] = useState("Poisar");
    const [fees, setFees] = useState([]);
    const {user, setUser} = useContext(UserContext);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const startDateP = searchParams.get("startDate");
        const endDateP = searchParams.get("endDate");
        const branchP = searchParams.get("branch");
        if(startDateP === null){return;}
        const dateRangeP = [new Date(startDateP), new Date(endDateP)];
        setDateRange(dateRangeP);
        setBranch(branchP);
        updateReport(dateRangeP, branchP);
    }, [searchParams]);

    useEffect(() => {
        if(!dateRange || dateRange.length === 0){return;}
        const params = {startDate: dateRange[0], endDate: dateRange[1], branch: branch};
        navigate({pathname: '/feeCollectionReport', search: `?${createSearchParams(params)}`}, {replace: true});
    }, [dateRange, branch]);

    const updateReport = async (dateRangeP, branchP) => {
        const startDate = moment(dateRangeP[0]).format("yyyy-MM-DD");
        const endDate = moment(dateRangeP[1]).format("yyyy-MM-DD");
        let feesList = await fetchFeesByDateofJoin(startDate, endDate, branchP);
        feesList.sort((a, b) => {
            if(moment(a.date).isBefore(b.date)) return -1;
            else if(moment(a.date).isAfter(b.date)) return 1;
            else {
                return parseInt(a.receiptNumber) - parseInt(b.receiptNumber);
            }
        });
        setFees([...feesList]);
    }

    const findAllBranchTotal = (feeList, b) => {
        return feeList.filter((fee) => fee.branch === b).reduce((total, fee) => {
            return total + parseInt(fee.money);
        }, 0);
    }

    const findTotalByDateAndBranch = (feeList, date, b) => {
        return feeList.filter((fee) => fee.branch === b && fee.date === date).reduce((total, fee) => {
            return total + parseInt(fee.money);
        }, 0);
    }

    const generateXLSX = async () => {
        const startDate = moment(dateRange[0]).format("yyyy-MM-DD");
        const endDate = moment(dateRange[1]).format("yyyy-MM-DD");
        let feesList = await fetchFeesByDateofJoin(startDate, endDate, "All");

        const aoa = [];
        const totals = ["total"];
        const row3 = ["date"];
        branches.forEach((b) => {
            totals.push(findAllBranchTotal(feesList, b));
            row3.push(b[0]);
        })
        aoa.push(totals);
        aoa.push([`${moment(startDate).format("DD/MM/YYYY")} - ${moment(endDate).format("DD/MM/YYYY")}`]);
        aoa.push(row3);
        const curDate = moment(startDate)
        while(curDate.isSameOrBefore(moment(endDate))){
            const curRow = [curDate.format("D")];
            branches.forEach((b) => {
                curRow.push(findTotalByDateAndBranch(feesList, curDate.format("yyyy-MM-DD"), b));
            });
            aoa.push(curRow);
            curDate.add(1, 'days');
        }

        const workbook = utils.book_new();
        const worksheet = utils.aoa_to_sheet(aoa);
        utils.book_append_sheet(workbook, worksheet, "Sheet1", true);
        writeFile(workbook, "out.xlsx");
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Fee Collection Report {fees.length !== 0 && `(${fees.length})`}
                        <BasicButton onClick={generateXLSX}>Export</BasicButton>
                    </PageHeader>
                    <CustomInput label={"Date Range"} type="dateRange" value={dateRange} onChangeValue={(v) => {
                        setDateRange(v);
                    }}/>
                    <CustomInput label={"Branch"} type="dropdown" value={branch} onChangeValue={(v) => {
                        setBranch(v);
                    }} menuItems={branchesWithAll}/>
                    
                    {
                        branches.map((b) => {
                            return (branch === "All" || branch === b) ? <FeeCollectionTable feeList={fees.filter((fee) => fee.branch === b)} branchName={b}/> : <></>
                        })
                    }
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
}

export default FeeCollectionReport;