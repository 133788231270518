import React, { useState } from "react";
import CustomInput from "../../commons/CustomInput";
import { BasicButton, ContentContainer, PageContainer, PageContentContainer, PageHeader } from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import { branches, categoryOfExpense } from "../../utils/constants";
import { v4 as uuidv4 } from 'uuid';
import { fetchLockingDate, saveExpense } from "../../utils/FirebaseUtil";
import moment from "moment";

const SendExpenses = () => {

    const [expense, setExpense] = useState({});

    const handleSaveExpense = async () => {
        const expenseData = expense;
        
        const lockingDate = await fetchLockingDate();
        if(moment(expenseData.date).isBefore(moment(lockingDate))){
            alert("Expense too old to add!");
            return;
        }
        expenseData.uuid = uuidv4();
        const isSaved = await saveExpense(expenseData);
        if(isSaved){
            alert("Done!");
            setExpense({branch: "", category: "", amount: "", date: "", desc: ""});
        }else{
            alert("Error!");
        }
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Send Expenses</PageHeader>

                    <CustomInput label={"Select Branch"} type="dropdown" value={expense.branch} onChangeValue={(v) => {
                        setExpense({...expense, branch: v});
                    }} menuItems={branches}/>
                    <CustomInput label={"Category of Expense"} type="dropdown" value={expense.category} onChangeValue={(v) => {
                        setExpense({...expense, category: v});
                    }} menuItems={categoryOfExpense}/>
                    <CustomInput label={"Amount"} type="number" value={expense.amount} onChangeValue={(v) => {
                        setExpense({...expense, amount: v});
                    }} placeholder="₹"/>
                    <CustomInput label={"Date"} type="date" value={expense.date} onChangeValue={(v) => {
                        setExpense({...expense, date: v});
                    }}/>
                    <CustomInput label={"Description"} value={expense.desc} onChangeValue={(v) => {
                        setExpense({...expense, desc: v});
                    }} placeholder="Expense Description"/>
                    <BasicButton onClick={handleSaveExpense}>Save Expense</BasicButton>

                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
}

export default SendExpenses;