import React, { useContext, useEffect, useState } from "react";
import { BasicButton, ContentContainer, PageContainer, PageContentContainer, PageHeader } from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import CustomInput from "../../commons/CustomInput";
import { batchTimingStringToNumber, batchTimings, branches, branchesWithAll, coursesListWithAll } from "../../utils/constants";
import { fetchStudentByDateofJoin, fetchStudentsByBatchTiming } from "../../utils/FirebaseUtil";
import UserContext from "../../commons/context";
import BatchTable from "../../commons/BatchTable";
import moment from "moment";
import 'rsuite/dist/rsuite-no-reset.min.css';
import AdmissionTable from "../../commons/AdmissionTable";
import { utils, writeFile } from "xlsx";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

const AdmissionReport = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [dateRange, setDateRange] = useState();
    const [branch, setBranch] = useState("All");
    const [course, setCourse] = useState("All");
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const {user, setUser} = useContext(UserContext);

    useEffect(() => {
        const startDateP = searchParams.get("startDate");
        const endDateP = searchParams.get("endDate");
        const branchP = searchParams.get("branch");
        if(startDateP === null){return;}
        const dateRangeP = [new Date(startDateP), new Date(endDateP)];
        setDateRange(dateRangeP);
        setBranch(branchP);
        updateReport(dateRangeP, branchP);
    }, [searchParams]);

    useEffect(() => {
        if(!dateRange || dateRange.length === 0){return;}
        const params = {startDate: dateRange[0], endDate: dateRange[1], branch: branch};
        navigate({pathname: '/admissionReport', search: `?${createSearchParams(params)}`}, {replace: true});
    }, [dateRange, branch]);

    useEffect(() => {
        filterByCourse();
    }, [course, students]);

    const updateReport = async (dateRangeP, branchP) => {
        console.log("searching");
        const startDate = moment(dateRangeP[0]).format("yyyy-MM-DD");
        const endDate = moment(dateRangeP[1]).format("yyyy-MM-DD");
        let studList = await fetchStudentByDateofJoin(startDate, endDate);
        studList = studList.map((stud) => {
            const feesPaid = stud.fees.reduce((total, fee) => {
                return total + parseInt(fee.money);
            }, 0);
            return {...stud, feesPaid};
        });
        if(branchP !== "All"){
            studList = studList.filter((stud) => stud.branch === branchP);
        }
        setStudents([...studList]);
    }

    const filterByCourse = () => {
        if(course === "All"){
            setFilteredStudents(students);
        }else{
            setFilteredStudents(students.filter((stud) => stud.course.map((c) => c.courseName).includes(course)));
        }
    }

    const generateXLSX = async () => {
        const studsList = filteredStudents;

        const aoa = [];
        const row1 = ["Reg. Num", "Date of Join", "Full Name", "Date of Birth", "Address", "Student's Mobile Num", "Parent's Mobile Num", "Branch", "Course", "Batch Timing", "Total Fees", "Paid Fees"];
        aoa.push(row1);

        studsList.forEach((stud) => {
            const curRow = [
                stud.regNum,
                moment(stud.doj).format("DD/MM/YYYY"),
                stud.name,
                moment(stud.dob).format("DD/MM/YYYY"),
                stud.address,
                stud.mobileNumber,
                stud.parentMobileNumber,
                stud.branch,
                stud.course.map((c) => c.courseName).join(", "),
                batchTimings[parseInt(stud.batchTiming) - 7],
                stud.totalFees,
                stud.fees.reduce((total, fee) => {
                    return total + parseInt(fee.money);
                }, 0)
            ];
            aoa.push(curRow);
        })

        const workbook = utils.book_new();
        const worksheet = utils.aoa_to_sheet(aoa);
        utils.book_append_sheet(workbook, worksheet, "Sheet1", true);
        writeFile(workbook, "out.xlsx");
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>
                        Admission Report {filteredStudents.length !== 0 && `(${filteredStudents.length})`}
                        <BasicButton onClick={generateXLSX}>Export</BasicButton>
                    </PageHeader>
                    <CustomInput label={"Date Range"} type="dateRange" value={dateRange} onChangeValue={(v) => {
                        setDateRange(v);
                    }}/>
                    <CustomInput label={"Branch"} type="dropdown" value={branch} onChangeValue={(v) => {
                        setBranch(v);
                    }} menuItems={branchesWithAll}/>
                    <CustomInput label={"Course"} type="dropdown" value={course} onChangeValue={(v) => {
                        setCourse(v);
                    }} menuItems={coursesListWithAll}/>
                    <AdmissionTable students={filteredStudents}/>
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
}

export default AdmissionReport;