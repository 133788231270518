import React, { useContext, useEffect, useState } from "react";
import { BasicButton, ContentContainer, EmptyVerticalHeight, PageContainer, PageContentContainer, PageHeader } from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import CustomInput from "../../commons/CustomInput";
import { batchTimingStringToNumber, batchTimings, branches, branchesWithAll, categoryOfExpense } from "../../utils/constants";
import { fetchExpenses, fetchFeesByDateofJoin, fetchStudentByDateofJoin, fetchStudentsByBatchTiming } from "../../utils/FirebaseUtil";
import UserContext from "../../commons/context";
import BatchTable from "../../commons/BatchTable";
import moment from "moment";
import 'rsuite/dist/rsuite-no-reset.min.css';
import AdmissionTable from "../../commons/AdmissionTable";
import FeeCollectionTable from "../../commons/FeeCollectionTable";
import ExpensesReportTable from "../../commons/ExpensesReportTable";
import styled from "styled-components";
import { utils, writeFile } from "xlsx";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

const GrandTotalContainer = styled.div`
    padding: 1.2rem;
    font-size: 1.2rem;
    font-weight: bold;
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #ff4d4d;
    @media (max-width: 600px) {
        font-size: 1rem;
    }
`;

const ViewExpenses = () => {

    const [dateRange, setDateRange] = useState();
    const [branch, setBranch] = useState("Poisar");
    const [expenses, setExpenses] = useState([]);
    const [fees, setFees] = useState([]);

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const startDateP = searchParams.get("startDate");
        const endDateP = searchParams.get("endDate");
        const branchP = searchParams.get("branch");
        if(startDateP === null){return;}
        const dateRangeP = [new Date(startDateP), new Date(endDateP)];
        setDateRange(dateRangeP);
        setBranch(branchP);
        fetchExpensesData(dateRangeP, branchP);
        fetchCollectionData(dateRangeP, branchP);
    }, [searchParams]);

    useEffect(() => {
        if(!dateRange || dateRange.length === 0){return;}
        const params = {startDate: dateRange[0], endDate: dateRange[1], branch: branch};
        navigate({pathname: '/expensesReport', search: `?${createSearchParams(params)}`}, {replace: true});
    }, [dateRange, branch]);

    const fetchExpensesData = async (dateRangeP, branchP) => {
        // if(dateRange == undefined){return;}
        const startDate = moment(dateRangeP[0]).format("yyyy-MM-DD");
        const endDate = moment(dateRangeP[1]).format("yyyy-MM-DD");
        let expensesList = await fetchExpenses(startDate, endDate, branchP);
        setExpenses([...expensesList]);
    }

    const fetchCollectionData = async (dateRangeP, branchP) => {
        // if(dateRange == undefined){return;}
        const startDate = moment(dateRangeP[0]).format("yyyy-MM-DD");
        const endDate = moment(dateRangeP[1]).format("yyyy-MM-DD");
        let feesList = await fetchFeesByDateofJoin(startDate, endDate, branchP);
        setFees([...feesList]);
    }

    const findGrandTotal = () => {
        return expenses.reduce((total, exp) => {
            return total + parseInt(exp.amount);
        }, 0);
    }

    const findFeeTotal = () => {
        let grandTotal = fees.reduce((total, fee) => {
            return total + parseInt(fee.money);
        }, 0);
        return grandTotal;
    }

    const findBranchTotalExpense = (expList, b) => {
        return expList.filter((exp) => exp.branch === b).reduce((total, exp) => {
            return total + parseInt(exp.amount);
        }, 0);
    }

    const findBranchAndCategoryTotalExpense = (expList, b, cat) => {
        return expList.filter((exp) => exp.branch === b && exp.category === cat).reduce((total, exp) => {
            return total + parseInt(exp.amount);
        }, 0);
    }

    const listByBranchAndCategory = (expList, b, cat) => {
        const expensesList = expList.filter((exp) => exp.branch === b && exp.category === cat);
        expensesList.sort((a, b) => {
            if(moment(a.date).isBefore(b.date)) return -1;
            else if(moment(a.date).isAfter(b.date)) return 1;
            else return 0;
        });
        return expensesList.map((exp) => {
            return ["", "", exp.amount, moment(exp.date).format("DD/MM/YYYY"), exp.desc];
        });
    }

    const generateXLSX = async () => {
        const startDate = moment(dateRange[0]).format("yyyy-MM-DD");
        const endDate = moment(dateRange[1]).format("yyyy-MM-DD");
        let expensesList = await fetchExpenses(startDate, endDate, "All");

        const aoa = [];
        const row1 = ["From", moment(startDate).format("DD/MM/YYYY"), "", "To", moment(endDate).format("DD/MM/YYYY")];
        aoa.push(row1);

        branches.forEach((b) => {
            aoa.push([findBranchTotalExpense(expensesList, b), b]);
            categoryOfExpense.forEach((cat) => {
                aoa.push(["", findBranchAndCategoryTotalExpense(expensesList, b, cat), cat]);
                aoa.push(...listByBranchAndCategory(expensesList, b, cat));
                aoa.push([""]);
            })
        });

        const workbook = utils.book_new();
        const worksheet = utils.aoa_to_sheet(aoa);
        utils.book_append_sheet(workbook, worksheet, "Sheet1", true);
        writeFile(workbook, "out.xlsx");
    }


    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Expenses Report
                        <BasicButton onClick={generateXLSX}>Export</BasicButton>
                    </PageHeader>
                    <CustomInput label={"Date Range"} type="dateRange" value={dateRange} onChangeValue={(v) => {
                        setDateRange(v);
                    }}/>
                    <CustomInput label={"Branch"} type="dropdown" value={branch} onChangeValue={(v) => {
                        setBranch(v);
                    }} menuItems={branchesWithAll}/>
                    {
                        branches.map((b) => {
                            return (branch === "All" || branch === b) ? <ExpensesReportTable feeList={fees.filter((fee) => fee.branch === b)} expenseList={expenses.filter((exp) => exp.branch === b)} branchName={b}/> : <></>
                        })
                    }
                </ContentContainer>
                <EmptyVerticalHeight />
                <GrandTotalContainer>Grand Total: ₹{findFeeTotal()} - ₹{findGrandTotal()} = ₹{findFeeTotal() - findGrandTotal()}/-</GrandTotalContainer>
            </PageContentContainer>
        </PageContainer>
    );
}

export default ViewExpenses;