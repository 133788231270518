export const branches = ["Poisar", "Bihari Tekri", "Ganesh Nagar", "Hanuman Nagar", "Nallasopara"];
export const branchesWithAll = ["All", "Poisar", "Bihari Tekri", "Ganesh Nagar", "Hanuman Nagar", "Nallasopara"];
export const expensesFilter = ["All", "Pending", "Admission Done"];
// certi - Typing6, MSCIT, CCC
export const coursesList = ["DMM", "Advance Tally Prime with GST", "Advance Excel", "DOFA", "DTP", "HTML", "Autocad", "C++", "MSCIT", "CCC", "Web Design", "Computer Typing - English", "Computer Typing - Hindi", "Computer Typing - Marathi", "E-Way Bill", "Python", "Typing-6Months", "Computer Awareness Course", "DCGA", "DOA", "Hardware", "DMO(Office Awareness)", "Java", "Multimedia", "CorelDraw", "Photoshop"];
export const coursesListFullForm = {
    "DMM": "Diploma in MS-Office & Multimedia",
    "DOFA": "Diploma in Office Automation & Financial Accounting",
    "DTP": "Desktop Publishing"
};
export const topicList = ["MS-Word", "MS-Excel", "MS-Powerpoint", "MS-Access", "Tally Prime", "GST", "Autocad", "DTP", "HTML", "C++", "MSCIT", "CCC", "Web Design", "Typing", "E-Way Bill", "Python", "Typing-6Months", "Computer Awareness Course", "Hardware", "Java", "Multimedia", "CorelDraw", "Photoshop", "Adv Excel", "Internet"];
export const recordModes = ["Admission", "Fees"];
export const coursesListWithAll = ["All", "DMM", "Advance Tally Prime", "Advance Excel", "DOFA", "DTP", "HTML", "Autocad", "C++", "MSCIT", "CCC", "Web Design", "Typing", "E-Way Bill", "Python", "Typing-6Months", "Computer Awareness Course", "DCGA", "DOA", "Hardware", "DMO(Office Awareness)", "Java", "Multimedia", "CorelDraw", "Photoshop"];
export const categoryOfExpense = ["Bank", "(R) Director Salary", "(S) Director Salary", "Fixed Expense", "Faculty Salary", "Other Expense"];
export const batchTimings = ["7 AM", "8 AM", "9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM"];
export const modeOfPayment = ["Cash", "Cheque", "Online"];

export const modalStyles = {
    content: {
        width: "90%",
        maxWidth: "600px",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        borderRadius: "1rem",
        border: "0",
        backgroundImage: "linear-gradient(to bottom, #ececec, #d8d8d8)",
    },
    overlay: {
        background: "#000000a6",

        zIndex: 1000,
    },
};

export const getBalance = (totalFees, fees) => {
    return parseFloat(totalFees) - fees.reduce((acc, cur) => {
        return acc + parseFloat(cur.money)
    }, 0);
}

export const valid = (data) => {
    if (data == undefined || data == null || data == "") {
        return false;
    }
    return true;
};

export const batchTimingStringToNumber = (batchTiming) => {
    const [time, zone] = batchTiming.split(" ");
    let timeInNumber = parseInt(time);
    if(zone == "PM" && time !== "12"){
        timeInNumber += 12;
    }
    return timeInNumber.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
}

// 13 -> 01 PM
export const batchTimingNumberToString = (batchTiming) => {
    const batchTimingNum = parseInt(batchTiming);
    return batchTimings[batchTimingNum - 7];
}