import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Admission.module.css";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, setDoc, doc, getDoc, addDoc } from 'firebase/firestore/lite';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment/moment";
import { CheckCircle, Error } from "@mui/icons-material";
import CustomInput, { MultiSelectDropdownCustomInput } from "../../commons/CustomInput";
import { useEffect } from "react";
import { batchTimingStringToNumber, batchTimings, branches, coursesList } from "../../utils/constants";
import { BasicButton, ContentContainer, PageContainer, PageContentContainer, PageHeader } from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import styled from "styled-components";
import sidegallery from "../../assets/sidegallery.png";
import { uploadImage, uploadImages, uploadPhotoForm, uploadPhotoImage } from "../../utils/FirebaseUtil";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API,
    authDomain: "star-classroom-app.firebaseapp.com",
    databaseURL: "https://star-classroom-app.firebaseio.com",
    projectId: "star-classroom-app",
    storageBucket: "star-classroom-app.appspot.com",
    messagingSenderId: "739084819156",
    appId: "1:739084819156:web:9ddee2c1a00160588e3941",
    measurementId: "G-WFLESSH1D0"
  };

const AdmissionPageContainer = styled.div`
    display: flex;
`;

const AdmissionFormSection = styled.div`
    /* width: 100%; */
    display: flex;
    flex-direction: column;

    @media (max-width: 700px) {
        flex: 1;
    }
`;

const SideGallerySection = styled.div`
    margin-left: 2rem;
    flex: 1;
    display: flex;
    background-image: url(${sidegallery});
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;

    @media (max-width: 700px) {
        display: none;
    }

`;

const Admission = () => {

    const app = initializeApp(firebaseConfig);
    const navigate = useNavigate();
    const [stud, setStud] = useState({});
    const [pass, setPass] = useState("");
    const [isUserExists, setIsUserExists] = useState(-1);

    const valid = (data) => {
        if(data == undefined || data == null || data == ""){
            return false;
        }
        return true;
    }

    const uploadAdmission = async () => {

        const db = getFirestore(app);
        if(valid(stud.regNum) && valid(stud.doj) && valid(stud.studName) && valid(stud.dob) && valid(stud.gender) && valid(stud.qualification) && valid(stud.motherName) && valid(stud.address) && valid(stud.phoneNum) && valid(stud.parentsNum) && valid(stud.aadharNum) && valid(stud.branch) && valid(stud.batchTiming) && valid(stud.courses) && valid(stud.courseDuration) && valid(stud.courseFees) && valid(stud.regFees) && valid(stud.inquiryName) && valid(stud.inquiryDate) && valid(stud.admissionName) && valid(pass)){

            const passRef = doc(db, "passwords", "faculty");
            const passSnap = await getDoc(passRef);
            if(passSnap.data().pass != pass){
                alert("Wrong Password");
                return;
            }

            const docRef = doc(db, "users", `${stud.regNum}`);
            const docSnap = await getDoc(docRef);

            if(!docSnap.exists()){
                const courseFeesN = parseInt(stud.courseFees);
                const regFeesN = parseInt(stud.regFees);
                const batchTiming = batchTimingStringToNumber(stud.batchTiming);
                let photoURL = "";
                let formURL = "";
                if(valid(stud.studPhoto)){
                    photoURL = await uploadPhotoImage(stud.regNum, stud.studPhoto);
                }
                if(valid(stud.admPhoto)){
                    formURL = await uploadPhotoForm(stud.regNum, stud.admPhoto);
                }

                await setDoc(doc(db, "users", `${stud.regNum}`), {
                    'absent': [],
                    'activated': "false",
                    'address': valid(stud.address) ? stud.address : "AAA",
                    'adharNumber': valid(stud.aadharNum) ? `${stud.aadharNum}` : "0",
                    'admissionName': stud.admissionName,
                    'batchTiming': batchTiming,
                    'branch': valid(stud.branch) ? stud.branch : 'Poisar',
                    'course': stud.courses,  
                    'courseUpdates': [],
                    'dob': valid(stud.dob) ? stud.dob : "2000-01-01",
                    'doj': valid(stud.doj) ? stud.doj : moment().format("YYYY-MM-DD"),
                    'duration': valid(stud.courseDuration) ? stud.courseDuration : '0',
                    'fees': [],
                    'gender': valid(stud.gender) ? stud.gender : "Male",
                    'inquiryDate': stud.inquiryDate,
                    'inquiryName': stud.inquiryName,
                    'installment': '',
                    'instruct': '',
                    'messages': [],
                    'mobileNumber': stud.phoneNum ? stud.phoneNum : "000",
                    'motherName': valid(stud.motherName) ? stud.motherName : "A",
                    'name': valid(stud.studName) ? stud.studName : "A",
                    'over': "false",
                    'overDate': '',
                    'parentMobileNumber': valid(stud.parentsNum) ? stud.parentsNum : "000",
                    'photoUrl': photoURL,
                    'qualification': valid(stud.qualification) ? stud.qualification : 'A',
                    'regNum': `${stud.regNum}`,
                    'regDate': stud.doj,
                    'signUrl': formURL,
                    'totalFees': `${courseFeesN + regFeesN}`
                });
                alert("Registration Done!");
                navigate("/");
            }else {
                alert("Registration Number already exists");
            }
        }else{
            alert("Fill all the details first!");
        }
    }

    const handleRegNumCheck = async () => {
        if(!valid(stud.regNum)){
            alert("Enter registration number first");
            return;
        }
        const db = getFirestore(app);
        const docRef = doc(db, "users", `${stud.regNum}`);
        const docSnap = await getDoc(docRef);

        if(docSnap.exists()){
            setIsUserExists(1);
        }else{
            setIsUserExists(0);
        }
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Admission Form</PageHeader>
                    <AdmissionPageContainer>
                        <AdmissionFormSection>
                            <div className={styles.inputContainer}>
                                <span>Registration Number*</span>
                                <input
                                    style={{color: "black"}}
                                    value={stud.regNum}
                                    onChange={(e) => {setStud({...stud, regNum: e.target.value})}}
                                    type="text"
                                    placeholder="Student's Reg Num"
                                    maxLength={"5"}
                                />
                                <button className={`${styles.regNumCheck} ${isUserExists == -1 ? styles.regNumCheck : isUserExists == 0 ? styles.validCheckBtn : styles.invalidCheckBtn}`} onClick={handleRegNumCheck}>{
                                    isUserExists == -1 ? "Check" : 
                                    isUserExists == 0 ? <><CheckCircle className={styles.buttonIcons} fontSize="medium" /> Available</> : <><Error className={styles.buttonIcons} fontSize="medium" /> Already registered</>
                                }</button>
                            </div>
                            <CustomInput
                                label={"Date of Join"}
                                type="date"
                                value={stud.doj}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, doj: v });
                                }}
                                placeholder=""
                            />
                            <CustomInput
                                label={"Full Name"}
                                type="text"
                                value={stud.studName}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, studName: v });
                                }}
                                placeholder="Student's Full Name"
                            />
                            <CustomInput
                                label={"Date of Birth"}
                                type="date"
                                value={stud.dob}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, dob: v });
                                }}
                                placeholder=""
                            />
                            <CustomInput
                                label={"Gender"}
                                type="dropdown"
                                value={stud.gender}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, gender: v });
                                }}
                                menuItems={["Male", "Female"]}
                            />
                            <CustomInput
                                label={"Qualification"}
                                type="text"
                                value={stud.qualification}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, qualification: v });
                                }}
                                placeholder="Student's Qualification"
                            />
                            <CustomInput
                                label={"Mother's Name"}
                                type="text"
                                value={stud.motherName}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, motherName: v });
                                }}
                                placeholder="Mother's Name"
                            />
                            <CustomInput
                                label={"Address"}
                                type="text"
                                value={stud.address}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, address: v });
                                }}
                                placeholder="Address"
                            />
                            <CustomInput
                                label={"Student's WhatsApp Num"}
                                type="phone"
                                value={stud.phoneNum}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, phoneNum: v });
                                }}
                                placeholder="0000000000"
                                extras={{"maxLength": "10"}}
                            />
                            <CustomInput
                                label={"Parents's Phone Num"}
                                type="phone"
                                value={stud.parentsNum}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, parentsNum: v });
                                }}
                                placeholder="0000000000"
                                extras={{"maxLength": "10"}}
                            />
                            <CustomInput
                                label={"Aadhar Number"}
                                type="phone"
                                value={stud.aadharNum}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, aadharNum: v });
                                }}
                                placeholder="000000000000"
                                extras={{"maxLength": "12"}}
                            />
                            <CustomInput label={"Branch"} type="dropdown" value={stud.branch} onChangeValue={(v) => {
                                setStud({...stud, branch: v});
                            }} menuItems={branches}/>
                            <CustomInput label={"Batch Timing"} type="dropdown" value={stud.batchTiming} onChangeValue={(v) => {
                                setStud({...stud, batchTiming: v});
                            }} menuItems={batchTimings}/>
                            <MultiSelectDropdownCustomInput label={"Courses"} values={stud.courses ? stud.courses : []} setValues={(v) => {
                                setStud({...stud, courses: v});
                            }} menuItems={coursesList}/>
                            <CustomInput
                                label={"Course Duration"}
                                type="number"
                                value={stud.courseDuration}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, courseDuration: v });
                                }}
                                placeholder="in months"
                            />
                            <CustomInput
                                label={"Course Fees"}
                                type="number"
                                value={stud.courseFees}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, courseFees: v });
                                }}
                                placeholder="₹"
                            />
                            <CustomInput
                                label={"Registration Fees"}
                                type="number"
                                value={stud.regFees}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, regFees: v });
                                }}
                                placeholder="₹"
                            />
                            <CustomInput
                                label={"Inquiry By:"}
                                type="text"
                                value={stud.inquiryName}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, inquiryName: v });
                                }}
                                placeholder="Faculty Name"
                            />
                            <CustomInput
                                label={"Inquiry Date:"}
                                type="date"
                                value={stud.inquiryDate}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, inquiryDate: v });
                                }}
                            />
                            <CustomInput
                                label={"Admission By:"}
                                type="text"
                                value={stud.admissionName}
                                onChangeValue={(v) => {
                                    setStud({ ...stud, admissionName: v });
                                }}
                                placeholder="Faculty Name"
                            />
                            <CustomInput
                                label={"Photo"}
                                type="image"
                                onChangeValue={async (v) => {
                                    setStud({ ...stud, studPhoto: v });
                                }}
                            />
                            <CustomInput
                                label={"Admission Form"}
                                type="image"
                                onChangeValue={(v) => {
                                    setStud({ ...stud, admPhoto: v });
                                }}
                            />
                            <CustomInput
                                label={"Password"}
                                type="password"
                                value={pass}
                                onChangeValue={(v) => {
                                    setPass(v);
                                }}
                                placeholder="Faculty Password"
                            />
                            <BasicButton onClick={uploadAdmission}>SAVE</BasicButton>
                        </AdmissionFormSection>
                        <SideGallerySection>

                        </SideGallerySection>
                    </AdmissionPageContainer>
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );

    // return <div className={styles.inquiryPageContainer}>
    //     <div className={styles.navbarContainer}>
    //         Star Computer Point Plus
    //     </div>
    //     <div className={styles.contentContainer}>
    //         <div className={styles.inquiryFormContainer}>
    //             <span className={styles.header}>Admission Form</span>
                
    //             <div className={styles.inputContainer}>
    //                 <span>Date of Join</span>
    //                 <input
    //                     value={doj}
    //                     onChange={(e) => {setDoj(e.target.value)}}
    //                     type="date"
    //                 />
    //             </div>
    //             <div className={styles.inputContainer}>
    //                 <span>Full Name*</span>
    //                 <input
    //                     value={studName}
    //                     onChange={(e) => {setStudName(e.target.value)}}
    //                     type="text"
    //                     placeholder="Student's Full Name"
    //                 />
    //             </div>
    //             <div className={styles.inputContainer}>
    //                 <span>Date of Birth</span>
    //                 <input
    //                     value={dob}
    //                     onChange={(e) => {setDob(e.target.value)}}
    //                     type="date"
    //                 />
    //             </div>
    //             <div className={styles.inputContainerDropdown}>
    //                 <span>Gender</span>
    //                 <FormControl fullWidth>
    //                     <InputLabel id="demo-simple-select-label">Gender</InputLabel>
    //                     <Select
    //                         labelId="demo-simple-select-label"
    //                         id="demo-simple-select"
    //                         value={gender}
    //                         label="Gender"
    //                         onChange={(e) => {setGender(e.target.value);}}
    //                     >
    //                         <MenuItem value={'Male'}>Male</MenuItem>
    //                         <MenuItem value={'Female'}>Female</MenuItem>
    //                     </Select>
    //                 </FormControl>
    //             </div>
    //             <div className={styles.inputContainer}>
    //                 <span>Qualification</span>
    //                 <input
    //                     value={qualification}
    //                     onChange={(e) => {setQualification(e.target.value)}}
    //                     type="text"
    //                     placeholder="Qualification"
    //                 />
    //             </div>
    //             <div className={styles.inputContainer}>
    //                 <span>Mother's Name</span>
    //                 <input
    //                     value={motherName}
    //                     onChange={(e) => {setMotherName(e.target.value)}}
    //                     type="text"
    //                     placeholder="Mother's Name"
    //                 />
    //             </div>
    //             <div className={styles.inputContainer}>
    //                 <span>Address</span>
    //                 <input
    //                     value={address}
    //                     onChange={(e) => {setAddress(e.target.value)}}
    //                     type="text"
    //                     placeholder="Address"
    //                 />
    //             </div>
    //             <div className={styles.inputContainer}>
    //                 <span>Student's WhatsApp Num</span>
    //                 <input
    //                     value={phoneNum}
    //                     onChange={(e) => {setPhoneNum(e.target.value)}}
    //                     type="phone"
    //                     maxLength="10"
    //                     placeholder="Phone Number"
    //                 />
    //             </div>
    //             <div className={styles.inputContainer}>
    //                 <span>Parents's Phone Num</span>
    //                 <input
    //                     value={parentsNum}
    //                     onChange={(e) => {setParentsNum(e.target.value)}}
    //                     type="phone"
    //                     maxLength="10"
    //                     placeholder="Phone Number"
    //                 />
    //             </div>
    //             <div className={styles.inputContainer}>
    //                 <span>Aadhar Number</span>
    //                 <input
    //                     value={aadharNum}
    //                     onChange={(e) => {setAadharNum(e.target.value)}}
    //                     type="number"
    //                     placeholder="Aadhar Number"
    //                 />
    //             </div>
    //             <MultiSelectDropdownCustomInput label={"Courses"} values={courses} setValues={(v) => {
    //                 setCourses(v);
    //             }} menuItems={coursesList}/>
    //             <div className={styles.inputContainer}>
    //                 <span>Course Duration</span>
    //                 <input
    //                     value={courseDuration}
    //                     onChange={(e) => {setCourseDuration(e.target.value)}}
    //                     type="number"
    //                     placeholder="In months"
    //                 />
    //             </div>
    //             <div className={styles.inputContainerDropdown}>
    //                 <span>Branch Name</span>
    //                 <FormControl fullWidth>
    //                     <InputLabel id="demo-simple-select-label">Branch Name</InputLabel>
    //                     <Select
    //                         labelId="demo-simple-select-label"
    //                         id="demo-simple-select"
    //                         value={branch}
    //                         label="Branch Name"
    //                         onChange={(e) => {setBranch(e.target.value);}}
    //                     >
    //                         <MenuItem value={"Poisar"}>Poisar</MenuItem>
    //                         <MenuItem value={"Bihari Tekri"}>Bihari Tekri</MenuItem>
    //                         <MenuItem value={"Ganesh Nagar"}>Ganesh Nagar</MenuItem>
    //                         <MenuItem value={"Hanuman Nagar"}>Hanuman Nagar</MenuItem>
    //                         <MenuItem value={"Nallasopara"}>Nallasopara</MenuItem>
    //                     </Select>
    //                 </FormControl>
    //             </div>
    //             <div className={styles.inputContainer}>
    //                 <span>Course Fees</span>
    //                 <input
    //                     value={courseFees}
    //                     onChange={(e) => {setCourseFees(e.target.value)}}
    //                     type="number"
    //                     placeholder="Course Fees"
    //                 />
    //             </div>
    //             <div className={styles.inputContainer}>
    //                 <span>Reg Fees</span>
    //                 <input
    //                     value={regFees}
    //                     onChange={(e) => {setRegFees(e.target.value)}}
    //                     type="number"
    //                     placeholder="Reg Fees"
    //                 />
    //             </div>
    //             <div className={styles.inputContainer}>
    //                 <span>Password*</span>
    //                 <input
    //                     value={pass}
    //                     onChange={(e) => {setPass(e.target.value)}}
    //                     type="password"
    //                     placeholder="Faculty Password"
    //                 />
    //             </div>
    //             <button className={styles.submitBtn} onClick={uploadInquiry}>Submit</button>
    //         </div>
    //     </div>
    // </div>
}

export default Admission;


// Faculty Name, Date, 