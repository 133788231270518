import React, { useEffect, useState } from "react";
import CustomInput from "../../commons/CustomInput";
import {
    BasicButton,
    ContentContainer,
    PageContainer,
    PageContentContainer,
    PageHeader,
} from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import { branches, categoryOfExpense, modeOfPayment, valid } from "../../utils/constants";
import { v4 as uuidv4 } from "uuid";
import {
    deleteExpense,
    deletePayment,
    fetchExpenseByUUID,
    fetchFeeByUUID,
    fetchPasswords,
    saveExpense,
    updatePasswords,
    updatePayment,
} from "../../utils/FirebaseUtil";
import { useNavigate, useParams } from "react-router-dom";

const EditPassword = () => {
    const navigate = useNavigate();
    const [pass, setPass] = useState({});
    const [admissionPass, setAdmissionPass] = useState("");
    const [adminPass, setAdminPass] = useState("");
    const [lockingDate, setLockingDate] = useState();

    useEffect(() => {
        fetchPass();
    }, []);

    const fetchPass = async () => {
        const passDetail = await fetchPasswords();
        setPass(passDetail.faculty);
        setAdmissionPass(passDetail.admission);
        setAdminPass(passDetail.admin);
        setLockingDate(passDetail.lockingDate);
    };

    const handlePasswordUpdate = async () => {
        branches.forEach((branch) => {
            if(!valid(pass[branch])){
                alert("Fill all passwords!");
                return;
            }
        })
        if(!valid(adminPass)){
            alert("Fill all passwords!");
            return;
        }
        const isPasswordUpdated = await updatePasswords(pass, admissionPass, adminPass, lockingDate);
        if(isPasswordUpdated){
            alert("Updated!");
            navigate(-1);
        }else{
            alert("Error!");
        }
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Edit Password</PageHeader>
                    {
                        branches.map((branch) => {
                            return <CustomInput
                                label={branch}
                                type="text"
                                value={pass[branch]}
                                onChangeValue={(v) => {
                                    setPass({
                                        ...pass,
                                        [branch]: v,
                                    });
                                }}
                            />
                        })
                    }

                    <CustomInput
                        label={"Admission Pass"}
                        type="text"
                        value={admissionPass}
                        onChangeValue={(v) => {
                            setAdmissionPass(v);
                        }}
                    />
                    <CustomInput
                        label={"Admin Pass"}
                        type="text"
                        value={adminPass}
                        onChangeValue={(v) => {
                            setAdminPass(v);
                        }}
                    />
                    <CustomInput
                        label={"Locking Date"}
                        type="date"
                        value={lockingDate}
                        onChangeValue={(v) => {
                            setLockingDate(v);
                        }}
                    />
                    <BasicButton onClick={handlePasswordUpdate}>
                        Update Passwords
                    </BasicButton>
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
};

export default EditPassword;
