import React, { useContext, useEffect, useState } from "react";
import { BasicButton, ContentContainer, PageContainer, PageContentContainer, PageHeader } from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import CustomInput from "../../commons/CustomInput";
import { batchTimingStringToNumber, batchTimings, branches, branchesWithAll, coursesListWithAll, recordModes } from "../../utils/constants";
import { fetchFeesByDateofJoin, fetchStudentByDateofJoin, fetchStudentsByBatchTiming } from "../../utils/FirebaseUtil";
import UserContext from "../../commons/context";
import BatchTable from "../../commons/BatchTable";
import moment from "moment";
import 'rsuite/dist/rsuite-no-reset.min.css';
import AdmissionTable from "../../commons/AdmissionTable";
import { utils, writeFile } from "xlsx";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import AdmissionTableForFaculty from "../../commons/AdmissionTableForFaculty";
import FeeCollectionTable from "../../commons/FeeCollectionTable";
import FeeCollectionTableForFaculty from "../../commons/FeeCollectionTableForFaculty";

const RecordList = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [dateRange, setDateRange] = useState();
    const [mode, setMode] = useState("");
    const [students, setStudents] = useState([]);
    const [feeReceipts, setFeeReceipts] = useState([]);
    const {user, setUser} = useContext(UserContext);

    useEffect(() => {
        const startDateP = searchParams.get("startDate");
        const endDateP = searchParams.get("endDate");
        const modeP = searchParams.get("mode");
        if(startDateP === null){return;}
        const dateRangeP = [new Date(startDateP), new Date(endDateP)];
        setDateRange(dateRangeP);
        setMode(modeP);
        if(modeP === 'Admission'){
            fetchAdmissionReport(dateRangeP);
        }else if(modeP === 'Fees'){
            fetchFeesReport(dateRangeP);
        }
    }, [searchParams]);

    useEffect(() => {
        if(!dateRange || dateRange.length === 0){return;}
        const params = {startDate: dateRange[0], endDate: dateRange[1], mode: mode};
        navigate({pathname: '/recordList', search: `?${createSearchParams(params)}`}, {replace: true});
    }, [dateRange, mode]);

    const fetchAdmissionReport = async (dateRangeP) => {
        console.log("searching");
        const startDate = moment(dateRangeP[0]).format("yyyy-MM-DD");
        const endDate = moment(dateRangeP[1]).format("yyyy-MM-DD");
        let studList = await fetchStudentByDateofJoin(startDate, endDate);
        studList = studList.filter((stud) => stud.branch === user.branch);
        studList.sort((a, b) => {
            if(moment(a.doj).isBefore(b.doj)) return -1;
            else if(moment(a.doj).isAfter(b.doj)) return 1;
            else {
                return parseInt(a.regNum) - parseInt(b.regNum);
            }
        });
        setStudents([...studList]);
    }

    const fetchFeesReport = async (dateRangeP) => {
        const startDate = moment(dateRangeP[0]).format("yyyy-MM-DD");
        const endDate = moment(dateRangeP[1]).format("yyyy-MM-DD");
        let feesList = await fetchFeesByDateofJoin(startDate, endDate, user.branch);
        feesList.sort((a, b) => {
            if(moment(a.date).isBefore(b.date)) return -1;
            else if(moment(a.date).isAfter(b.date)) return 1;
            else {
                return parseInt(a.receiptNumber) - parseInt(b.receiptNumber);
            }
        });
        setFeeReceipts([...feesList]);
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>
                        Record List
                    </PageHeader>
                    <CustomInput label={"Date Range"} type="dateRange" value={dateRange} onChangeValue={(v) => {
                        setDateRange(v);
                    }}/>
                    <CustomInput label={"Mode"} type="dropdown" value={mode} onChangeValue={(v) => {
                        setMode(v);
                    }} menuItems={recordModes}/>
                    {
                        mode === 'Admission' && <AdmissionTableForFaculty students={students}/>
                    }
                    {
                        mode === 'Fees' && <FeeCollectionTableForFaculty feeList={feeReceipts} branchName={user.branch}/>
                    }
                    
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
}

export default RecordList;