import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Inquiry.module.css";
import { initializeApp } from "firebase/app";
import {
    getFirestore,
    collection,
    getDocs,
    setDoc,
    doc,
    getDoc,
    addDoc,
} from "firebase/firestore/lite";
import {
    BasicButton,
    ContentContainer,
    PageContainer,
    PageContentContainer,
    PageHeader,
} from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import CustomInput from "../../commons/CustomInput";
import styled from "styled-components";
import sidegallery from "../../assets/sidegallery.png";
import { submitInquiry } from "../../utils/FirebaseUtil";
import { branches, valid } from "../../utils/constants";
import { v4 as uuidv4 } from 'uuid';

const InquiryPageContainer = styled.div`
    display: flex;
`;

const InquiryFormSection = styled.div`
    /* width: 100%; */
    display: flex;
    flex-direction: column;

    @media (max-width: 700px) {
        flex: 1;
    }
`;

const SideGallerySection = styled.div`
    margin-left: 2rem;
    flex: 1;
    display: flex;
    background-image: url(${sidegallery});
    background-size: contain;
    background-position: center;

    @media (max-width: 700px) {
        display: none;
    }

`;

const Inquiry = () => {
    const navigate = useNavigate();
    const [inqData, setInqData] = useState({});
    const uploadInquiry = async () => {
        if (
            (inqData.studentName) &&
            valid(inqData.phoneNum) &&
            valid(inqData.address) &&
            valid(inqData.course) &&
            valid(inqData.branch) &&
            valid(inqData.date)
        ) {
            const inq = inqData;
            inq.uuid = uuidv4();
            const isDone = submitInquiry(inq);
            if(isDone){
                alert("Submitted!");
                navigate("/");
            }else{
                alert("Error!");
            }
        } else {
            alert("Fill all details");
        }
    };

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Inquiry Form</PageHeader>
                    <InquiryPageContainer>
                        <InquiryFormSection>
                            <CustomInput
                                label={"Full Name"}
                                value={inqData.studentName}
                                onChangeValue={(v) => {
                                    setInqData({ ...inqData, studentName: v });
                                }}
                                placeholder="Student's Full Name"
                            />
                            <CustomInput
                                label={"Phone Number"}
                                type="phone"
                                value={inqData.phoneNum}
                                onChangeValue={(v) => {
                                    setInqData({ ...inqData, phoneNum: v });
                                }}
                                placeholder="0000000000"
                                extras={{"maxLength": "10"}}
                            />
                            <CustomInput
                                label={"Address"}
                                value={inqData.address}
                                onChangeValue={(v) => {
                                    setInqData({ ...inqData, address: v });
                                }}
                                placeholder="Student's Address"
                            />
                            <CustomInput
                                label={"Course"}
                                value={inqData.course}
                                onChangeValue={(v) => {
                                    setInqData({ ...inqData, course: v });
                                }}
                                placeholder="Interested Course"
                            />
                            <CustomInput
                                label={"Date"}
                                type="date"
                                value={inqData.date}
                                onChangeValue={(v) => {
                                    setInqData({ ...inqData, date: v });
                                }}
                                placeholder="Inquiry Date"
                            />
                            <CustomInput label={"Select Branch"} type="dropdown" value={inqData.branch} onChangeValue={(v) => {
                                setInqData({...inqData, branch: v});
                            }} menuItems={branches}/>
                            <CustomInput
                                label={"Inquiry By"}
                                value={inqData.inqBy}
                                onChangeValue={(v) => {
                                    setInqData({ ...inqData, inqBy: v });
                                }}
                                placeholder="Faculty Name"
                            />
                            <BasicButton onClick={uploadInquiry}>SAVE</BasicButton>
                        </InquiryFormSection>
                        <SideGallerySection>

                        </SideGallerySection>
                    </InquiryPageContainer>
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
};

export default Inquiry;
