import { SearchRounded } from "@mui/icons-material"
import { InputContainer, SearchButton } from "./styles"

export const InputWithSearch = ({type ="text", value, onValueChange, placeholder, onSearchClick, extras = {}}) => {
    return <InputContainer>
        <input type={type} placeholder={placeholder} value={value} onChange={(e) => {onValueChange(e.target.value)}} onKeyDown={(e) => {
            if(e.key === 'Enter'){
                onSearchClick();
            }
        }} {...extras}/>
        <SearchButton onClick={onSearchClick}>
            <SearchRounded />
        </SearchButton>
    </InputContainer>
}