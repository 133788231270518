import React, { useEffect, useState } from "react";
import { BasicButton, ContentContainer, PageContainer, PageContentContainer, PageHeader } from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import { useNavigate, useParams } from "react-router-dom";
import { checkFacultyPassword, fetchStudent, updateStudent, uploadPhotoForm, uploadPhotoImage } from "../../utils/FirebaseUtil";
import CustomInput, { MultiSelectDropdownCustomInput } from "../../commons/CustomInput";
import { batchTimingNumberToString, batchTimingStringToNumber, batchTimings, branches, coursesList, valid } from "../../utils/constants";

const EditStudent = () => {
    const navigate = useNavigate();
    const params = useParams();
    const {reg} = params;
    const [stud, setStud] = useState({});
    const [submitPassword, setSubmitPassword] = useState("");
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        fetchStudentDetails();
    }, [reg]);

    const fetchStudentDetails = async () => {
        const studDetails = await fetchStudent(reg);
        setStud(studDetails);
    }

    const handleUpdateStudent = async () => {
        const passHash = await checkFacultyPassword(submitPassword);
        if(passHash !== ""){

            let photoUrl = "";
            let formUrl = "";
            if(valid(stud.studPhoto)){
                photoUrl = await uploadPhotoImage(stud.regNum, stud.studPhoto);
                stud.photoUrl = photoUrl;
            }
            if(valid(stud.admPhoto)){
                formUrl = await uploadPhotoForm(stud.regNum, stud.admPhoto);
                stud.formUrl = formUrl;
            }
            delete stud.studPhoto; delete stud.admPhoto;

            const isUpdated = await updateStudent(stud);
            if(isUpdated){
                alert("Done!");
                navigate(-1);
            } else{
                alert("Error!");
            }
        }else{
            alert("Wrong Password");
        }
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                {
                    stud && <ContentContainer>
                    <PageHeader>Edit Student {reg}</PageHeader>
                    <CustomInput label={"Reg Num"} type="number" value={stud.regNum} onChangeValue={(v) => {
                        setStud({...stud, regNum: v});
                    }} readOnly/>
                    <CustomInput label={"Name"} value={stud.name} onChangeValue={(v) => {
                        setStud({...stud, name: v});
                    }} placeholder="Student Name"/>
                    <CustomInput label={"Date of Birth"} type="date" value={stud.dob} onChangeValue={(v) => {
                        setStud({...stud, dob: v});
                    }}/>
                    <CustomInput
                        label={"Gender"}
                        type="dropdown"
                        value={stud.gender}
                        onChangeValue={(v) => {
                            setStud({ ...stud, gender: v });
                        }}
                        menuItems={["Male", "Female"]}
                    />
                    <CustomInput
                        label={"Qualification"}
                        type="text"
                        value={stud.qualification}
                        onChangeValue={(v) => {
                            setStud({ ...stud, qualification: v });
                        }}
                        placeholder="Student's Qualification"
                    />
                    <CustomInput label={"Mother's Name"} value={stud.motherName} onChangeValue={(v) => {
                        setStud({...stud, motherName: v});
                    }} placeholder="Mother's Name"/>
                    <CustomInput label={"Address"} value={stud.address} onChangeValue={(v) => {
                        setStud({...stud, address: v});
                    }} placeholder="Address"/>
                    <CustomInput label={"Student's WhatsApp Num"} type="phone" value={stud.mobileNumber} onChangeValue={(v) => {
                        setStud({...stud, mobileNumber: v});
                    }} placeholder="0000000000" extras={{maxLength:"10"}}/>
                    <CustomInput label={"Parent's Num"} type="phone" value={stud.parentMobileNumber} onChangeValue={(v) => {
                        setStud({...stud, parentMobileNumber: v});
                    }} placeholder="0000000000" extras={{maxLength:"10"}}/>
                    <CustomInput label={"Adhaar Number"} type="number" value={stud.adhaarNumber} onChangeValue={(v) => {
                        setStud({...stud, adhaarNumber: v});
                    }} placeholder="0000000000" extras={{maxLength:"12"}}/>
                    <CustomInput label={"Branch"} type="dropdown" value={stud.branch} onChangeValue={(v) => {
                        setStud({...stud, branch: v});
                    }} menuItems={branches}/>
                    <CustomInput label={"Batch Timing"} type="dropdown" value={batchTimingNumberToString(stud.batchTiming)} onChangeValue={(v) => {
                        setStud({...stud, batchTiming: batchTimingStringToNumber(v)});
                    }} menuItems={batchTimings}/>
                    <MultiSelectDropdownCustomInput label={"Courses"} values={stud.course ? stud.course : []} setValues={(v) => {
                        setStud({...stud, course: v});
                    }} menuItems={coursesList}/>
                    <CustomInput
                        label={"Course Duration"}
                        type="number"
                        value={stud.duration}
                        onChangeValue={(v) => {
                            setStud({ ...stud, duration: v });
                        }}
                        placeholder="in months"
                    />
                    <CustomInput label={"Total Fees"} type="number" value={stud.totalFees} onChangeValue={(v) => {
                        setStud({...stud, totalFees: v});
                    }}/>
                    <CustomInput label={"Installment (Amt, Due Date)"} value={stud.installment} onChangeValue={(v) => {
                        setStud({...stud, installment: v});
                    }}/>
                    <CustomInput
                        label={"Photo"}
                        type="image"
                        onChangeValue={async (v) => {
                            setStud({ ...stud, studPhoto: v });
                        }}
                    />
                    <CustomInput
                        label={"Admission Form"}
                        type="image"
                        onChangeValue={(v) => {
                            setStud({ ...stud, admPhoto: v });
                        }}
                    />
                    <CustomInput label={"Password"} type="password" value={submitPassword} onChangeValue={(v) => {
                        setSubmitPassword(v);
                    }}/>
                    <BasicButton onClick={handleUpdateStudent}>Save Changes</BasicButton>
                </ContentContainer>
                }
                
            </PageContentContainer>
        </PageContainer>
    )
}

export default EditStudent;