import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import {
    getFirestore,
    collection,
    getDocs,
    setDoc,
    doc,
    getDoc,
    addDoc,
} from "firebase/firestore/lite";
import {
    BasicButton,
    ContentContainer,
    DeletePostBtn,
    PageContainer,
    PageContentContainer,
    PageHeader,
    PostAuthorContent,
    PostAuthorImage,
    PostCard,
    PostCredit,
    PostDate,
    PostImage,
    PostMessage,
} from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import CustomInput from "../../commons/CustomInput";
import styled from "styled-components";
import scpplogo from "../../assets/scpplogo.png";
import { addPost, fetchAllPosts, submitInquiry, uploadPostImage } from "../../utils/FirebaseUtil";
import { branches, valid } from "../../utils/constants";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";

const PostPageContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const NoticeBoard = () => {
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const [newPost, setNewPost] = useState({});

    useEffect(() => {
        getAllPosts();
    }, []);

    const getAllPosts = async () => {
        const postList = await fetchAllPosts();
        console.log(postList);
        setPosts([...postList]);
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Notice Board</PageHeader>
                    <PostPageContainer>
                        {
                            posts.map((post) => {
                                return <PostCard>
                                    <PostCredit>
                                        <PostAuthorImage />
                                        <PostAuthorContent>
                                            SCPP
                                            <PostDate>{moment(post.date).format("h:mm A - DD/MM/YYYY")}</PostDate>
                                        </PostAuthorContent>
                                    </PostCredit>
                                    <PostImage src={post.image}/>
                                    <PostMessage>{post.text}</PostMessage>
                                </PostCard>
                            })
                        }
                    </PostPageContainer>
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
};

export default NoticeBoard;
