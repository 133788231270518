import React from "react";
import styled from "styled-components";
import cert from "../../assets/cert.jpg";
import moment from "moment";
import { coursesListFullForm } from "../../utils/constants";

const CertificateContainer = styled.div`
    width: 2480px;
    height: 3508px;
    position: relative;
    background-color: white;
    font-size: 3rem;
    font-weight: 600;
    /* background-image: url(${cert});
    background-size: contain;
    background-repeat: no-repeat; */
`;


const LearnerCodeBox = styled.div`
    position: absolute;
    top: 145px;
    left: 1850px;
`;

const NameBox = styled.div`
    position: absolute;
    top: 870px;
    left: 800px;
`;

const CourseBox = styled.div`
    position: absolute;
    top: 1000px;
    left: 500px;
`;

const RegBox = styled.div`
    position: absolute;
    top: 1100px;
    left: 600px;
`;

const ExamDateBox = styled.div`
    position: absolute;
    top: 1100px;
    left: 1800px;
`;

const TotalA = styled.div`
    position: absolute;
    top: 1770px;
    left: 1320px;
`;

const TotalI = styled.div`
    position: absolute;
    top: 1930px;
    left: 1320px;
`;

const TotalF = styled.div`
    position: absolute;
    top: 2090px;
    left: 1320px;
`;

const TotalT = styled.div`
    position: absolute;
    top: 2250px;
    left: 1320px;
`;

const PassingA = styled.div`
    position: absolute;
    top: 1770px;
    left: 1600px;
`;

const PassingI = styled.div`
    position: absolute;
    top: 1930px;
    left: 1600px;
`;

const PassingF = styled.div`
    position: absolute;
    top: 2090px;
    left: 1600px;
`;

const PassingT = styled.div`
    position: absolute;
    top: 2250px;
    left: 1600px;
`;

const ObtainedA = styled.div`
    position: absolute;
    top: 1770px;
    left: 1880px;
`;

const ObtainedI = styled.div`
    position: absolute;
    top: 1930px;
    left: 1880px;
`;

const ObtainedF = styled.div`
    position: absolute;
    top: 2090px;
    left: 1880px;
`;

const ObtainedT = styled.div`
    position: absolute;
    top: 2250px;
    left: 1880px;
`;

const Grade = styled.div`
    position: absolute;
    top: 2400px;
    left: 730px;
`;

const TodayDate = styled.div`
    position: absolute;
    top: 2400px;
    left: 1830px;
`;

const CertificatePage = ({data, course}) => {

    const getGrade = (p) => {
        if(p >= 80){
            return "A";
        }else if(p >= 60){
            return "B";
        }
        return "C";
    }

    const getCourseName = (c) => {
        if(coursesListFullForm[c]){
            return coursesListFullForm[c].toUpperCase();
        }else{
            return c.toUpperCase();
        }
    }

    return (
        course.courseName.includes("Typing") ?
            <CertificateContainer className={`certificate cert-${data.regNum}-${course.courseName}`}>
                <LearnerCodeBox>{data.regNum}</LearnerCodeBox>
                <NameBox>{data.name.toUpperCase()}</NameBox>
                <CourseBox>{getCourseName(course.courseName)}</CourseBox>
                <RegBox>{data.regNum}</RegBox>
                <ExamDateBox>{moment(data.examDate).format("DD/MM/YYYY")}</ExamDateBox>
                <TotalA>-</TotalA>
                <TotalI>-</TotalI>
                <TotalF>-</TotalF>
                <TotalT>-</TotalT>
                <PassingA>-</PassingA>
                <PassingI>-</PassingI>
                <PassingF>-</PassingF>
                <PassingT>-</PassingT>
                <ObtainedA>-</ObtainedA>
                <ObtainedI>-</ObtainedI>
                <ObtainedF>{course.percent} WPM</ObtainedF>
                <ObtainedT>{course.percent} WPM</ObtainedT>
                <Grade>{course.percent} WPM</Grade>
                <TodayDate>{moment().format("DD/MM/YYYY")}</TodayDate>
            </CertificateContainer> :
            <CertificateContainer className={`certificate cert-${data.regNum}-${course.courseName}`}>
                <LearnerCodeBox>{data.regNum}</LearnerCodeBox>
                <NameBox>{data.name.toUpperCase()}</NameBox>
                <CourseBox>{getCourseName(course.courseName)}</CourseBox>
                <RegBox>{data.regNum}</RegBox>
                <ExamDateBox>{moment(data.examDate).format("DD/MM/YYYY")}</ExamDateBox>
                <TotalA>20</TotalA>
                <TotalI>30</TotalI>
                <TotalF>50</TotalF>
                <TotalT>100</TotalT>
                <PassingA>10</PassingA>
                <PassingI>15</PassingI>
                <PassingF>25</PassingF>
                <PassingT>50</PassingT>
                <ObtainedA>{parseInt(parseInt(course.percent)*0.2)}</ObtainedA>
                <ObtainedI>{parseInt(parseInt(course.percent)*0.3)}</ObtainedI>
                <ObtainedF>{parseInt(course.percent) - parseInt(parseInt(course.percent)*0.2) - parseInt(parseInt(course.percent)*0.3)}</ObtainedF>
                <ObtainedT>{course.percent}</ObtainedT>
                <Grade>{getGrade(parseInt(course.percent))}</Grade>
                <TodayDate>{moment().format("DD/MM/YYYY")}</TodayDate>
            </CertificateContainer>
    );
}

export default CertificatePage;