import React, { useContext, useEffect, useState } from "react";
import { Navbar } from "../../commons/navbar";
import { CardGrid3Col, ContentContainer, EditButton, FeatureCard, FeatureCardIcon, PageContainer, PageContentContainer, PageHeader, SpaceBetweenRow } from "../../commons/styles";
import UserContext from "../../commons/context";
import { InputWithSearch } from "../../commons/InputWithSearch";
import { checkAdminPassword, checkBranchPassword, checkFacultyPassword, checkIfPassHashCorrect, checkIfPassHashCorrectForAdmin } from "../../utils/FirebaseUtil";
import { useNavigate } from "react-router-dom";
import { AddCircleRounded, ContactSupportRounded, CurrencyRupeeRounded, MovingRounded, NewspaperRounded, PasswordRounded, QuizRounded, RecentActors, RequestQuoteRounded, SearchRounded, WalletRounded } from "@mui/icons-material";

const AdminPage = () => {

    const {user, setUser} = useContext(UserContext);
    const [pass, setPass] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();

    const handlePassChange = (newPass) => {
        setPass(newPass);
    }

    const handlePassSubmit = async () => {
        const res = await checkAdminPassword(pass);
        const {passHash} = res;
        setPass("");
        setUser({pass: passHash});
        if(passHash === ""){
            alert("Wrong Password");
        }
    }

    const handleUserUpdate = async () => {
        const isAdminAuth = await checkIfPassHashCorrectForAdmin(user.pass);
        setIsAdmin(isAdminAuth);
    }

    useEffect(() => {
        handleUserUpdate();
    }, [user]);

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Admin Dashboard</PageHeader>
                    <InputWithSearch
                        type="password"
                        value={pass}
                        onValueChange={handlePassChange}
                        placeholder={"Password"}
                        onSearchClick={handlePassSubmit}
                    />
                    {isAdmin == true && <>
                        <CardGrid3Col>
                            <FeatureCard onClick={() => {
                                navigate("/admissionReport");
                            }}>
                                <FeatureCardIcon>
                                    <AddCircleRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Admission Report
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/batchwiseReport");
                            }}>
                                <FeatureCardIcon>
                                    <RecentActors fontSize="large"/>
                                </FeatureCardIcon>
                                Batchwise Report
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/studentSearch");
                            }}>
                                <FeatureCardIcon>
                                    <SearchRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Student Search
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/viewInquiry");
                            }}>
                                <FeatureCardIcon>
                                    <ContactSupportRounded fontSize="large"/>
                                </FeatureCardIcon>
                                View Inquiries
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/sendExpense");
                            }}>
                                <FeatureCardIcon>
                                    <CurrencyRupeeRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Send Expenses
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/feeCollectionReport");
                            }}>
                                <FeatureCardIcon>
                                    <MovingRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Fee Collection
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/expensesReport");
                            }}>
                                <FeatureCardIcon>
                                    <WalletRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Expenses Report
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/directorSalary");
                            }}>
                                <FeatureCardIcon>
                                    <RequestQuoteRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Director Salary
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/noticeBoardEdit");
                            }}>
                                <FeatureCardIcon>
                                    <NewspaperRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Notice Board
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/examList");
                            }}>
                                <FeatureCardIcon>
                                    <QuizRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Exam List
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/editPassword");
                            }}>
                                <FeatureCardIcon>
                                    <PasswordRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Edit Password
                            </FeatureCard>
                        </CardGrid3Col>
                    </>}
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
};

export default AdminPage;
