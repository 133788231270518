import { initializeApp } from "firebase/app";
import { addDoc, and, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, or, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore/lite";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import { sha256 } from "js-sha256";
import { branches, categoryOfExpense } from "./constants";
import feeData from "./feeList.json";
import moment from "moment";
import { ImportExportOutlined } from "@mui/icons-material";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API,
    authDomain: "star-classroom-app.firebaseapp.com",
    databaseURL: "https://star-classroom-app.firebaseio.com",
    projectId: "star-classroom-app",
    storageBucket: "star-classroom-app.appspot.com",
    messagingSenderId: "739084819156",
    appId: "1:739084819156:web:9ddee2c1a00160588e3941",
    measurementId: "G-WFLESSH1D0",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const submitInquiry = async (inq) => {
    try{
        await setDoc(doc(db, "inquiry", inq.uuid), inq);
        return true;
    } catch(e){
        console.log(e);
        return false;
    }
}

export const fetchStudent = async (regNum) => {
    const studentRef = doc(db, "users", `${regNum}`);
    const student = await getDoc(studentRef);
    return student.data();
};

export const checkAdminPassword = async (enteredPass) => {
    const passRef = doc(db, "passwords", "admin");
    const passSnap = await getDoc(passRef);
    if(passSnap.data().pass != enteredPass){
        return {passHash: ""};
    }
    return {passHash: sha256(passSnap.data().pass)};
}

export const checkBranchPassword = async (enteredPass) => {
    const passRef = doc(db, "passwords", "facultyweb");
    const passSnap = await getDoc(passRef); 
    const passList = passSnap.data();
    for(let i = 0; i < branches.length; ++i){
        if(passList[branches[i]] == enteredPass){
            return ({
                passHash: sha256(enteredPass),
                branch: branches[i]
            });
        }
    }
    return ({
        passHash: "",
        branch: ""
    });
}

export const checkFacultyPassword = async (enteredPass) => {
    const passRef = doc(db, "passwords", "faculty");
    const passSnap = await getDoc(passRef);
    if(passSnap.data().pass != enteredPass){
        return "";
    }
    return sha256(passSnap.data().pass);
}

export const checkIfPassHashCorrect = async (storedHash, branch) => {
    if(branch === "" || branch === undefined) return false;
    const passRef = doc(db, "passwords", "facultyweb");
    const passSnap = await getDoc(passRef); 
    const passList = passSnap.data();
    if(sha256(passList[branch]) == storedHash){
        return true;
    }
    return false;
}

export const checkIfPassHashCorrectForAdmin = async (storedHash) => {
    const passRef = doc(db, "passwords", "admin");
    const passSnap = await getDoc(passRef);
    return sha256(passSnap.data().pass) == storedHash;
}

export const updateStudent = async (newStud) => {
    try{
        const studentRef = doc(db, "users", `${newStud.regNum}`);
        await setDoc(studentRef, newStud, {merge: true});
        return true;
    }catch(e){
        console.log(e);
        return false;
    }
}

export const makePayment = async (feeData, branch, regNum, openingBal) => {
    try {
        const studentRef = doc(db, "users", `${regNum}`);
        await updateDoc(studentRef, {
            fees: arrayUnion(feeData)
        });
        await setDoc(doc(db, "fees", feeData.uuid), {...feeData, regNum, openingBal: `${openingBal}`, branch});
        return true;
    }catch(e){
        console.log(e);
        return false;
    }
}

export const addCourseUpdate = async (regNum, courseName, courseDate) => {
    try {
        const studentRef = doc(db, "users", `${regNum}`);
        await updateDoc(studentRef, {
            courseUpdates: arrayUnion({
                dolStart: `${courseDate}`,
                learnVideoIndex: "0",
                sectionName: courseName
            })
        });
        return true;
    } catch(e){
        console.log(e);
        return false;
    }
}

export const updateInstruction = async (regNum, newInstruct) => {
    try {
        const studentRef = doc(db, "users", `${regNum}`);
        await updateDoc(studentRef, {
            instruct: newInstruct
        });
        return true;
    } catch(e){
        console.log(e);
        return false;
    }
}

export const deleteStudent = async (regNum) => {
    try {
        await deleteDoc(doc(db, "users", regNum));
        return true;
    } catch(e){
        console.log(e);
        return false;
    }
}



export const toggleCourseOver = async (regNum) => {
    try {
        const studentRef = doc(db, "users", `${regNum}`);
        const student = await getDoc(studentRef);
        const stud = student.data();
        let toSet;
        if(stud.over && stud.over === "true"){
            toSet = "false";
        }else{
            toSet = "true";
        }
        await updateDoc(studentRef, {
            over: toSet
        });
        return true;
    } catch(e){
        console.log(e);
        return false;
    }
}

export const updatePayment = async (feeData) => {
    try {
        const studentRef = doc(db, "users", `${feeData.regNum}`);
        const sp = await getDoc(studentRef);
        let fees = sp.data().fees;
        fees = fees.map((fee) => {
            if(fee.uuid === feeData.uuid){
                return feeData;
            }else{
                return fee;
            }
        })
        await updateDoc(studentRef, {
            fees: fees
        });
        await setDoc(doc(db, "fees", feeData.uuid), {...feeData});
        return true;
    }catch(e){
        console.log(e);
        return false;
    }
}

export const fetchPasswords = async () => {
    try {
        const passRef = doc(db, "passwords", "facultyweb");
        const passSnap = await getDoc(passRef); 
        const passList = passSnap.data();
        const adminPassRef = doc(db, "passwords", "admin");
        const adminPassSnap = await getDoc(adminPassRef); 
        const adminPass = adminPassSnap.data();
        const admissionPass = (await getDoc(doc(db, "passwords", "faculty"))).data();
        const lockingDate = (await getDoc(doc(db, "passwords", "locking"))).data();
        return {
            faculty: passList,
            admin: adminPass.pass,
            admission: admissionPass.pass,
            lockingDate: lockingDate.date,
        };
    }catch(e){
        console.log(e);
        return false;
    }
}

export const fetchLockingDate = async () => {
    try {
        const lockingDate = (await getDoc(doc(db, "passwords", "locking"))).data();
        return lockingDate.date;
    }catch(e){
        console.log(e);
        return "";
    }
}

export const updatePasswords = async (facultyPass, admissionPass, adminPass, lockingDate) => {
    try {
        await setDoc(doc(db, "passwords", "facultyweb"), facultyPass);
        await setDoc(doc(db, "passwords", "admin"), {pass: adminPass});
        await setDoc(doc(db, "passwords", "faculty"), {pass: admissionPass});
        await setDoc(doc(db, "passwords", "locking"), {date: lockingDate});
        return true;
    }catch(e){
        console.log(e);
        return false;
    }
}

export const deletePayment = async (feeData) => {
    try {
        const studentRef = doc(db, "users", `${feeData.regNum}`);
        const sp = await getDoc(studentRef);
        let fees = sp.data().fees;
        fees = fees.filter((fee) => fee.uuid !== feeData.uuid);
        await updateDoc(studentRef, {
            fees: fees
        });
        await deleteDoc(doc(db, "fees", feeData.uuid));
        return true;
    }catch(e){
        console.log(e);
        return false;
    }
}

export const saveExpense = async (expenseData) => {
    try{
        const expenseRef = doc(db, "newexpenses", expenseData.uuid);
        await setDoc(expenseRef, expenseData, {merge: true});
        // const expenseRef = doc(db, "expense", branch);
        // await updateDoc(expenseRef, {
        //     [category]: arrayUnion(expenseData)
        // });
        return true;
    } catch(e){
        console.log(e);
        return false;
    }
}

export const deleteExpense = async (expenseData) => {
    try{
        await deleteDoc(doc(db, "newexpenses", expenseData.uuid));
        return true;
    } catch(e){
        console.log(e);
        return false;
    }
}

export const fetchStudentsByBatchTiming = async (timing, branch) => {
    try {
        const studentRef = collection(db, "users");
        const q = query(studentRef, where("batchTiming", "==", `${timing}`), where("branch", "==", branch));
        const studentsSnapshot = await getDocs(q);
        const studentList = []
        studentsSnapshot.forEach((doc) => {
            studentList.push(doc.data());
        });
        return studentList;
    } catch (e){
        console.log(e);
        return [];
    }
}

export const fetchStudentByDateofJoin = async (start, end) => {
    try {
        const studentRef = collection(db, "users");
        const q = query(studentRef, where("doj", ">=", start), where("doj", "<=", end));
        const studentsSnapshot = await getDocs(q);
        const studentList = []
        studentsSnapshot.forEach((doc) => {
            studentList.push(doc.data());
        });
        return studentList;
    } catch (e){
        console.log(e);
        return [];
    }
}

export const fetchStudentByExamDate = async (start, end, branch) => {
    try {
        const studentRef = collection(db, "users");
        let q;
        if(branch === "All"){
            q = query(studentRef, where("examDate", ">=", start), where("examDate", "<=", end));
        }else{
            q = query(studentRef, where("examDate", ">=", start), where("examDate", "<=", end), where("branch", "==", branch));
        }
        const studentsSnapshot = await getDocs(q);
        const studentList = []
        studentsSnapshot.forEach((doc) => {
            studentList.push(doc.data());
        });
        return studentList;
    } catch (e){
        console.log(e);
        return [];
    }
}

export const updateMultipleStudentPercent = async (studList) => {
    
    try {
        for(let i = 0; i < studList.length; ++i){
            const studentRef = doc(db, "users", `${studList[i].regNum}`);
            await setDoc(studentRef, {course: studList[i].course}, {merge: true});
        }
        
        return true;
    } catch (e){
        console.log(e);
        return false;
    }
}

export const updateCertificatePrintedDate = async (regNum, cName, isAdd) => {
    
    try {
        const studentRef = doc(db, "users", `${regNum}`);
        const sp = await getDoc(studentRef);
        let courses = sp.data().course;
        courses = courses.map((c) => {
            if(c.courseName === cName){
                return {
                    ...c,
                    printDate: isAdd ? moment().format("yyyy-MM-DD") : ""
                };
            }else{
                return c;
            }
        });

        await updateDoc(studentRef, {
            course: courses
        });
        
        return true;
    } catch (e){
        console.log(e);
        return false;
    }
}

export const fetchFeesByDateofJoin = async (start, end, branch) => {
    try {
        const feeRef = collection(db, "fees");
        let q;
        if(branch === "All"){
            q = query(feeRef, where("date", ">=", start), where("date", "<=", end));
        }else{
            q = query(feeRef, where("date", ">=", start), where("date", "<=", end), where("branch", "==", branch));
        }
        const feesSnapshot = await getDocs(q);
        const feeList = []
        feesSnapshot.forEach((doc) => {
            feeList.push(doc.data());
        });
        return feeList;
    } catch (e){
        console.log(e);
        return [];
    }
}

export const fetchExpenses = async (start, end, branch) => {
    try {
        const expenseRef = collection(db, "newexpenses");
        let q;
        if(branch === "All"){
            q = query(expenseRef, where("date", ">=", start), where("date", "<=", end));
        }else{
            q = query(expenseRef, where("date", ">=", start), where("date", "<=", end), where("branch", "==", branch));
        }
        const expensesSnapshot = await getDocs(q);
        const expensesList = [];
        expensesSnapshot.forEach((doc) => {
            expensesList.push(doc.data());
        });
        return expensesList;
    } catch (e){
        console.log(e);
        return [];
    }
}       

export const fetchDirectorSalary = async (start, end) => {
    try {
        const expenseRef = collection(db, "newexpenses");
        let q = query(expenseRef, and(where("date", ">=", start), where("date", "<=", end), or(where('category', "==", categoryOfExpense[1]), where('category', "==", categoryOfExpense[2]))));
        const expensesSnapshot = await getDocs(q);
        const expensesList = [];
        expensesSnapshot.forEach((doc) => {
            expensesList.push(doc.data());
        });
        return expensesList;
    } catch (e){
        console.log(e);
        return [];
    }
}

export const fetchExpenseByUUID = async (uuid) => {
    try {
        const expenseRef = doc(db, "newexpenses", uuid);
        const expense = await getDoc(expenseRef);
        return expense.data();
    } catch (e){
        console.log(e);
        return {};
    }
}           

export const fetchFeeByUUID = async (uuid) => {
    try {
        const feeRef = doc(db, "fees", uuid);
        const fee = await getDoc(feeRef);
        return fee.data();
    } catch (e){
        console.log(e);
        return {};
    }
}         

export const fetchInquiriesByDate = async (start, end, branch) => {
    try {
        const inquiryRef = collection(db, "inquiry");
        let q;
        if(branch === "All"){
            q = query(inquiryRef, where("date", ">=", start), where("date", "<=", end));
        }else{
            q = query(inquiryRef, where("date", ">=", start), where("date", "<=", end), where("branch", "==", branch));
        }
        const inquirySnapshot = await getDocs(q);
        const inqList = []
        inquirySnapshot.forEach((doc) => {
            inqList.push(doc.data());
        });
        return inqList;
    } catch (e){
        console.log(e);
        return [];
    }
}

export const fetchAllPosts = async () => {
    try {

        const postsRef = collection(db, "posts");
        const q = query(postsRef);
        const postsSnap = await getDocs(q);
        const postsList = [];
        postsSnap.forEach((doc) => {
            postsList.push({...doc.data(), uuid: doc.id});
        })
        return postsList;

    } catch (e){
        console.log(e);
        return [];
    }
}

export const addPost = async (uuid, postData) => {
    try {
        await setDoc(doc(db, "posts", uuid), postData);
        return true;
    } catch (e){
        console.log(e);
        return false;
    }
}

export const deletePost = async (uuid) => {
    try {
        await deleteDoc(doc(db, "posts", uuid));
        return true;
    } catch (e){
        console.log(e);
        return false;
    }
}

export const uploadPhotoImage = async (regNum, photo) => {
    try{
        const storage = getStorage();
        const storageRefPhoto = ref(storage, `/images/${regNum}photo`);
        await uploadBytes(storageRefPhoto, photo);
        const downloadURLPhoto = await getDownloadURL(storageRefPhoto);
        return downloadURLPhoto;
    } catch (e){
        console.log(e);
        return {};
    }
}

export const uploadPhotoForm = async (regNum, photo) => {
    try{
        const storage = getStorage();
        const storageRefPhoto = ref(storage, `/images/${regNum}sign`);
        await uploadBytes(storageRefPhoto, photo);
        const downloadURLPhoto = await getDownloadURL(storageRefPhoto);
        return downloadURLPhoto;
    } catch (e){
        console.log(e);
        return {};
    }
}

export const uploadPostImage = async (uuid, photo) => {
    try{
        const storage = getStorage();
        const storageRefPhoto = ref(storage, `/posts/${uuid}`);
        await uploadBytes(storageRefPhoto, photo);
        const downloadURLPhoto = await getDownloadURL(storageRefPhoto);
        return downloadURLPhoto;
    } catch (e){
        console.log(e);
        return {};
    }
}



export const fetchAllFees = async () => {
    try{
        console.log("clicked..");
        // const studentRef = collection(db, "users");
        // const students = await getDocs(query(studentRef));
        // const feeList = []
        // students.forEach((doc) => {
        //     feeList.push(...(doc.data().fees.map((fee) => {
        //         return {...fee, regNum: doc.data().regNum, branch: doc.data().branch, openingBal: "0"}
        //     })));
        // });
        // console.log(feeList);
        // // const feeList = [{sadf: "Asdf"}]

        // var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(feeList));
        // var dlAnchorElem = document.getElementById('downloadAnchorElem');
        // dlAnchorElem.setAttribute("href",     dataStr     );
        // dlAnchorElem.setAttribute("download", "feeList.json");
        // dlAnchorElem.click();

        // const feesRef = collection(db, "fees");
        // const feesSnap = await getDocs(query(feesRef));
        // const doneFeeList = [];
        // feesSnap.forEach((fee) => {
        //     doneFeeList.push(fee.data().uuid);
        // });

        // console.log(doneFeeList);

        console.log(feeData);

        // const batch = writeBatch(db);
        // feeList.splice(400, 500).forEach((fee) => {
        //     if(!doneFeeList.includes(fee.uuid)){
        //         const feeRef = doc(db, "fees", fee.uuid);
        //         batch.set(feeRef, fee);
        //     }
        // });

        // await batch.commit();

        // console.log(feeList);
    }catch(e){
        console.log(e);
    }
}

export const syncFeeRec = async (feeList) => {
    try{
        console.log("clicked..");

        const batch = writeBatch(db);
        feeList.forEach((fee) => {
            const feeRef = doc(db, "fees", fee.uuid);
            batch.set(feeRef, fee);
        });

        await batch.commit();
        console.log("saved!");

    }catch(e){
        console.log(e);
    }
}

export const syncExpRec = async (expList) => {
    try{
        console.log("clicked..");

        const batch = writeBatch(db);
        expList.forEach((exp) => {
            const expRef = doc(db, "newexpenses", exp.uuid);
            batch.set(expRef, exp);
        });

        await batch.commit();
        console.log("saved!");

    }catch(e){
        console.log(e);
    }
}