import moment from "moment";
import React from "react";
import styled from "styled-components";
import { BasicButton, ClickableCell, TableCell, TableHeader } from "./styles";
import { getBalance } from "../utils/constants";
import { useNavigate } from "react-router-dom";

const TableGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 8px;
    overflow: hidden;
`;

const BottomActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BalanceFee = styled.div`
    margin-top: 1rem;
`;

const ColorCodeContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 1rem 0;
`;

const ColorCode = styled.div`
    display: flex;
    align-items: center;
`;

const ColorCircle = styled.div`
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: ${props => props.color};
    margin-right: 0.6rem;
`;

const FeeColorCell = styled.div`
    color: ${props => props.color};
`;

const FeesTableInProfile = ({fees, totalFees, onAddFeeButton}) => {
    const navigate = useNavigate();
    return (
        <>
            <TableGrid>
                <TableHeader>Recpt No.</TableHeader>
                <TableHeader>Date</TableHeader>
                <TableHeader>Amount</TableHeader>
                {
                    fees.map((fee, index) => {
                        return <>
                            <TableCell>{fee.receiptNumber}</TableCell>
                            <TableCell>{moment(fee.date).format("DD/MM/YYYY")}</TableCell>
                            <FeeColorCell color={fee.mopIndex === "0" ? "#000000" : fee.mopIndex === "1" ? "#1d91f7" : "#ff4343"}>
                                <ClickableCell><TableCell onClick={() => {
                                    navigate(`/editFee/${fee.uuid}`);
                                }}>₹{fee.money}/-</TableCell></ClickableCell>
                            </FeeColorCell>
                        </>
                    })
                }
                <TableHeader>Total</TableHeader>
                <TableHeader></TableHeader>
                <TableHeader>₹{fees.reduce((total, fee) => {
                return total + parseInt(fee.money);
            }, 0)}/-</TableHeader>
            </TableGrid>
            <ColorCodeContainer>
                <ColorCode>
                    <ColorCircle color="#000000"/>
                    Cash
                </ColorCode>
                <ColorCode>
                    <ColorCircle color="#1d91f7"/>
                    Cheque
                </ColorCode>
                <ColorCode>
                    <ColorCircle color="#ff4343"/>
                    Online
                </ColorCode>
            </ColorCodeContainer>
            <BottomActions>
                <BalanceFee>Balance Fees: ₹{getBalance(totalFees, fees)}/-</BalanceFee>
                <BasicButton onClick={onAddFeeButton}>
                    Add Fee
                </BasicButton>
            </BottomActions>
        </>
    );
}

export default FeesTableInProfile;