import React, { useContext, useEffect, useState } from "react";
import { Navbar } from "../../commons/navbar";
import { CardGrid3Col, ContentContainer, EditButton, FeatureCard, FeatureCardIcon, PageContainer, PageContentContainer, PageHeader, SpaceBetweenRow } from "../../commons/styles";
import UserContext from "../../commons/context";
import { InputWithSearch } from "../../commons/InputWithSearch";
import { checkAdminPassword, checkBranchPassword, checkFacultyPassword, checkIfPassHashCorrect, checkIfPassHashCorrectForAdmin } from "../../utils/FirebaseUtil";
import { useNavigate } from "react-router-dom";
import { ContactSupportRounded, CurrencyRupeeRounded, LowPriorityRounded, QuizRounded, RecentActors, SearchRounded } from "@mui/icons-material";

const FacultyPage = () => {

    const {user, setUser} = useContext(UserContext);
    const [pass, setPass] = useState("");
    const [isFaculty, setIsFaculty] = useState(false);
    const navigate = useNavigate();

    const handlePassChange = (newPass) => {
        setPass(newPass);
    }

    const handlePassSubmit = async () => {
        const res = await checkBranchPassword(pass);
        const {passHash, branch} = res;
        
        if(passHash !== ""){
            setUser({pass: passHash, branch: branch});
        }else{
            // check for admin
            const resAdmin = await checkAdminPassword(pass);
            const passHashAdmin = resAdmin.passHash;
            setUser({pass: passHashAdmin});
            if(passHashAdmin === ""){
                alert("Wrong password");
            }
            // if(passHashAdmin !== ""){
            //     navigate("/admin", {replace: true});
            // }else{
            //     alert("Wrong Password");
            // }
        }
        setPass("");
    }

    const handleUserUpdate = async () => {
        const isFacultyAuth = await checkIfPassHashCorrect(user.pass, user.branch);
        setIsFaculty(isFacultyAuth);
        if(!isFacultyAuth){
            const isAdminAuth = await checkIfPassHashCorrectForAdmin(user.pass);
            if(isAdminAuth){
                navigate("/admin", {replace: true});
            }
        }
    }

    useEffect(() => {
        handleUserUpdate();
    }, [user]);

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Faculty Dashboard</PageHeader>
                    <InputWithSearch
                        type="password"
                        value={pass}
                        onValueChange={handlePassChange}
                        placeholder={"Password"}
                        onSearchClick={handlePassSubmit}
                    />
                    {isFaculty == true && <>
                        <CardGrid3Col>
                            <FeatureCard onClick={() => {
                                navigate("/batchwiseReport");
                            }}>
                                <FeatureCardIcon>
                                    <RecentActors fontSize="large"/>
                                </FeatureCardIcon>
                                Batchwise Report
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/studentSearch");
                            }}>
                                <FeatureCardIcon>
                                    <SearchRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Student Search
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/viewInquiry");
                            }}>
                                <FeatureCardIcon>
                                    <ContactSupportRounded fontSize="large"/>
                                </FeatureCardIcon>
                                View Inquiries
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/sendExpense");
                            }}>
                                <FeatureCardIcon>
                                    <CurrencyRupeeRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Send Expenses
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/examList");
                            }}>
                                <FeatureCardIcon>
                                    <QuizRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Exam List
                            </FeatureCard>
                            <FeatureCard onClick={() => {
                                navigate("/recordList");
                            }}>
                                <FeatureCardIcon>
                                    <LowPriorityRounded fontSize="large"/>
                                </FeatureCardIcon>
                                Record List
                            </FeatureCard>
                        </CardGrid3Col>
                    </>}
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
};

export default FacultyPage;
