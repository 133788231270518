import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import {
    getFirestore,
    collection,
    getDocs,
    setDoc,
    doc,
    getDoc,
    addDoc,
} from "firebase/firestore/lite";
import {
    BasicButton,
    ContentContainer,
    DeletePostBtn,
    PageContainer,
    PageContentContainer,
    PageHeader,
    PostAuthorContent,
    PostAuthorImage,
    PostCard,
    PostCredit,
    PostDate,
    PostImage,
    PostMessage,
} from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import CustomInput from "../../commons/CustomInput";
import styled from "styled-components";
import scpplogo from "../../assets/scpplogo.png";
import { addPost, deletePost, fetchAllPosts, submitInquiry, uploadPostImage } from "../../utils/FirebaseUtil";
import { branches, valid } from "../../utils/constants";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import { CancelRounded } from "@mui/icons-material";

const PostPageContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const InquiryFormSection = styled.div`
    display: flex;
    flex-direction: column;
`;



const NoticeBoardEdit = () => {
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const [newPost, setNewPost] = useState({});

    useEffect(() => {
        getAllPosts();
    }, []);

    const getAllPosts = async () => {
        const postList = await fetchAllPosts();
        console.log(postList);
        postList.sort((a, b) => {
            if (a.date < b.date )
                return 1;
            if (a.date  > b.date )
                return -1;
            return 0;
        })
        setPosts([...postList]);
    }

    const sendPost = async () => {
        let photoURL = "";
        console.log(newPost);
        const uuid = uuidv4();
        if(valid(newPost.image)){
            photoURL = await uploadPostImage(uuid, newPost.image);
        }
        const isPostDone = await addPost(uuid, {date: moment().format("YYYY-MM-DD HH:mm:ss"), text: newPost.text, image: photoURL});
        if(isPostDone){
            alert("Posted!");
            getAllPosts();
            setNewPost({text: ""});
        }else{
            alert("Error");
        }
    }

    const handleDeletePost = async (uuidToDelete) => {
        const isDeleted = await deletePost(uuidToDelete);
        if(isDeleted){
            alert("Deleted!");
            getAllPosts();
        }else{
            alert("Error!");
        }
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Notice Board</PageHeader>
                    <PostPageContainer>
                        <InquiryFormSection>
                            <CustomInput
                                label={"Message"}
                                value={newPost.text}
                                onChangeValue={(v) => {
                                    setNewPost({ ...newPost, text: v });
                                }}
                                placeholder="Notice Message"
                            />
                            <CustomInput
                                label={"Photo"}
                                type="image"
                                onChangeValue={async (v) => {
                                    setNewPost({ ...newPost, image: v });
                                }}
                            />
                            <BasicButton onClick={sendPost}>POST</BasicButton>
                        </InquiryFormSection>
                        {
                            posts.map((post) => {
                                return <PostCard>
                                    <PostCredit>
                                        <PostAuthorImage />
                                        <PostAuthorContent>
                                            SCPP
                                            <PostDate>{moment(post.date).format("h:mm A - DD/MM/YYYY")}</PostDate>
                                        </PostAuthorContent>
                                        <DeletePostBtn onClick={() => {
                                            handleDeletePost(post.uuid);
                                        }}>
                                            <CancelRounded />
                                        </DeletePostBtn>
                                    </PostCredit>
                                    <PostImage src={post.image}/>
                                    <PostMessage>{post.text}</PostMessage>
                                </PostCard>
                            })
                        }
                    </PostPageContainer>
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
};

export default NoticeBoardEdit;
