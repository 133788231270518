import React from "react";
import { fetchAllFees, fetchExpenseByUUID, fetchExpenses, syncExpRec, syncFeeRec } from "../../utils/FirebaseUtil";
import feeData from "../../utils/feeList.json";
import expensesObj from "../../utils/expenses.json";
import { branches, categoryOfExpense } from "../../utils/constants";

const SyncFees = () => {

    const onButtonClick = async () => {
        // const feeList = feeData.filter((fee) => fee.date.substring(0, 4) === "2019");
        // console.log(feeList);
        // // // 4679
        // await syncFeeRec(feeList.slice(0, 500));
        // await syncFeeRec(feeList.slice(500, 1000));
        // // await syncFeeRec(feeList.slice(1000, 1500));
        // // await syncFeeRec(feeList.slice(1500, 1532));

        // const expensesObj = await fetchExpenses();
        // console.log(expensesObj);

        // const expensesList = [];
        // branches.forEach((branch) => {
        //     categoryOfExpense.forEach((cat) => {
        //         if(expensesObj[branch] && expensesObj[branch][cat]){
        //             expensesObj[branch][cat].forEach((exp) => {
        //                 expensesList.push({...exp, category: cat, branch: branch});
        //             })
        //         }
        //     })
        // });

        // const f = expensesList.filter((exp) => exp.uuid === "6067ab2b-840e-4653-886b-9db58aa37e4c");
        // console.log(f);

        // console.log(expensesList[0]);
        // const exp = await fetchExpenseByUUID(expensesList[5000].uuid);
        // console.log(exp);

        // 6898
        // await syncExpRec(expensesList.slice(3500, 4000));
        // await syncExpRec(expensesList.slice(4000, 4500));
        // await syncExpRec(expensesList.slice(5000, 5500));
        // await syncExpRec(expensesList.slice(5500, 6000));
        // await syncExpRec(expensesList.slice(6000, 6500));
        // await syncExpRec(expensesList.slice(6500, 6898));

    }

    return (
        <div>
            <button onClick={() => {
                onButtonClick();
            }}>View all fees</button>
            <button>Sync fees</button>
            <a id="downloadAnchorElem"></a>
        </div>
    );
}

export default SyncFees;