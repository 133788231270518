import moment from "moment";
import React from "react";
import styled from "styled-components";
import { BasicButton, TableCell, TableHeader } from "./styles";

const TableGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 8px;
    overflow: hidden;
`;

const BottomActions = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
`;

const CourseTableInProfile = ({courses, onAddCourseUpdate}) => {

    return (
        <>
            <TableGrid>
                <TableHeader>Course</TableHeader>
                <TableHeader>Date</TableHeader>
                {
                    courses.map((course, index) => {
                        return <>
                            <TableCell>{course.sectionName}</TableCell>
                            <TableCell>{moment(course.dolStart).format("DD/MM/YYYY")}</TableCell>
                        </>
                    })
                }
            </TableGrid>
            <BottomActions>
                {/* <BalanceFee>Balance Fees: ₹{getBalance(totalFees, fees)}/-</BalanceFee> */}
                <BasicButton onClick={onAddCourseUpdate}>
                    Add Course Update
                </BasicButton>
            </BottomActions>
        </>
    );
}

export default CourseTableInProfile;