import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Inquiry from "./pages/Inquiry/Inquiry";
import Courses from "./pages/Courses/Courses";
import Admission from "./pages/Admission/Admission";
import Gallery from "./pages/Gallery/Gallery";
import ViewInquiry from "./pages/ViewInquiry/ViewInquiry";
import StudentSearch from "./pages/StudentSearch/StudentSearch";
import UserContext from "./commons/context";
import { useState } from "react";
import FacultyPage from "./pages/Faculty/Faculty";
import EditStudent from "./pages/EditStudent/EditStudent";
import SendExpenses from "./pages/SendExpenses/SendExpenses";
import BatchwiseReport from "./pages/BatchwiseReport/BatchwiseReport";
import GoToTop from "./commons/GoToTop";
import FacultyProtectedRoute from "./commons/FacultyProtectedRoute";
import AdminPage from "./pages/Faculty/Admin";
import AdminProtectedRoute from "./commons/AdminProtectedRoute";
import AdmissionReport from "./pages/AdmissionReport/AdmissionReport";
import SyncFees from "./pages/DevRoutes/SyncFees";
import FeeCollectionReport from "./pages/FeeCollectionReport/FeeCollectionReport";
import ViewExpenses from "./pages/ViewExpenses/ViewExpenses";
import EditExpense from "./pages/SendExpenses/EditExpense";
import EditFee from "./pages/EditStudent/EditFee";
import DirectorSalary from "./pages/ViewExpenses/DirectorSalary";
import EditPassword from "./pages/Faculty/EditPassword";
import NoticeBoardEdit from "./pages/NoticeBoard/NoticeBoardEdit";
import NoticeBoard from "./pages/NoticeBoard/NoticeBoard";
import ExamList from "./pages/ExamList/ExamList";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import RecordList from "./pages/RecordList/RecordList";

const App = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home />,
        },
        {
            path: "/inquiry",
            element: <Inquiry />,
        },
        {
            path: "/viewInquiry",
            element: 
            <FacultyProtectedRoute>
              <ViewInquiry />
            </FacultyProtectedRoute>
        },
        {
            path: "/admission",
            element: (
                <>
                    <Admission />
                    <GoToTop />
                </>
            ),
        },
        {
            path: "/studentSearch",
            element: 
            <FacultyProtectedRoute>
              <StudentSearch />
            </FacultyProtectedRoute>
        },
        {
            path: "/studentSearch/:reg",
            element: 
            <FacultyProtectedRoute>
              <StudentSearch />
            </FacultyProtectedRoute>
        },
        {
            path: "/courses",
            element: <Courses />,
        },
        {
            path: "/gallery",
            element: <Gallery />,
        },
        {
            path: "/faculty",
            element: <FacultyPage />,
        },
        {
            path: "/admin",
            element: <AdminPage />,
        },
        {
            path: "/editStudent/:reg",
            element: 
            <FacultyProtectedRoute>
              <EditStudent />
            </FacultyProtectedRoute>
        },
        {
            path: "/sendExpense",
            element: <FacultyProtectedRoute>
              <SendExpenses />
            </FacultyProtectedRoute>,
        },
        {
            path: "/editExpense/:uuid",
            element: <FacultyProtectedRoute>
              <EditExpense />
            </FacultyProtectedRoute>,
        },
        {
            path: "/editFee/:uuid",
            element: <FacultyProtectedRoute>
              <EditFee />
            </FacultyProtectedRoute>,
        },
        {
            path: "/batchwiseReport",
            element: (
                <FacultyProtectedRoute>
                    <BatchwiseReport />
                </FacultyProtectedRoute>
            ),
        },
        {
            path: "/recordList",
            element: (
                <FacultyProtectedRoute>
                    <RecordList />
                </FacultyProtectedRoute>
            ),
        },
        {
            path: "/admissionReport",
            element: (
                <AdminProtectedRoute>
                    <AdmissionReport />
                </AdminProtectedRoute>
            ),
        },
        {
            path: "/feeCollectionReport",
            element: (
                <AdminProtectedRoute>
                    <FeeCollectionReport />
                </AdminProtectedRoute>
            ),
        },
        {
            path: "/expensesReport",
            element: (
                <AdminProtectedRoute>
                    <ViewExpenses />
                </AdminProtectedRoute>
            ),
        },
        {
            path: "/directorSalary",
            element: (
                <AdminProtectedRoute>
                    <DirectorSalary />
                </AdminProtectedRoute>
            ),
        },
        {
            path: "/editPassword",
            element: (
                <AdminProtectedRoute>
                    <EditPassword />
                </AdminProtectedRoute>
            ),
        },
        {
            path: "/noticeBoard",
            element: (
                <NoticeBoard />
            ),
        },
        {
            path: "/noticeBoardEdit",
            element: (
                <AdminProtectedRoute>
                    <NoticeBoardEdit />
                </AdminProtectedRoute>
            ),
        },
        {
            path: "/examList",
            element: (
                <FacultyProtectedRoute>
                    <ExamList />
                </FacultyProtectedRoute>
            ),
        },
        {
            path: "/dev",
            element: (
                <SyncFees />
            ),
        },
    ]);

    const [user, setUser] = useState({ pass: "" });

    return (
        <UserContext.Provider value={{ user, setUser }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <RouterProvider router={router}></RouterProvider>
            </LocalizationProvider>
        </UserContext.Provider>
    );
};

export default App;
