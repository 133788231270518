import React from "react";
import styles from "./Gallery.module.css";
import img1 from "../../assets/gallery/1.jpg";
import img3 from "../../assets/gallery/3.jpg";
import img4 from "../../assets/gallery/4.jpg";
import img5 from "../../assets/gallery/5.jpg";
import img6 from "../../assets/gallery/6.jpg";
import img7 from "../../assets/gallery/7.jpg";
import img8 from "../../assets/gallery/8.jpg";
import img9 from "../../assets/gallery/9.jpg";
import img10 from "../../assets/gallery/10.jpg";
import img11 from "../../assets/gallery/11.jpg";

const Gallery = () => {
    return (
        <div className={styles.coursesPageContainer}>
            <div className={styles.navbarContainer}>
                Star Computer Point Plus
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.galleryGrid}>
                    <img src={img1} />
                    <img src={img3} />
                    <img src={img7} />
                    <img src={img5} />
                    <img src={img6} />
                    <img src={img4} />
                    <img src={img8} />
                    <img src={img9} />
                    {/* <img src={img10} />
                    <img src={img11} /> */}
                </div>
            </div>
        </div>
    );
}

export default Gallery;