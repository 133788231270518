import React, { useEffect, useState } from "react";
import { BasicButton, ContentContainer, PageContainer, PageContentContainer, PageHeader } from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import CustomInput from "../../commons/CustomInput";
import { batchTimings, branchesWithAll, coursesListWithAll, valid } from "../../utils/constants";
import { fetchStudentByExamDate, updateCertificatePrintedDate, updateMultipleStudentPercent } from "../../utils/FirebaseUtil";
import moment from "moment";
import 'rsuite/dist/rsuite-no-reset.min.css';
import AdmissionTable from "../../commons/AdmissionTable";
import { utils, writeFile } from "xlsx";
import ExamTable from "../../commons/ExamTable";
import styled from "styled-components";
import CertificatePage from "./CertificatePage";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

const HideContainer = styled.div`
    overflow: hidden;
    height: 0;
`;

const ActionBar = styled.div`
    display: flex;
`;

const ExamList = () => {

    const [dateRange, setDateRange] = useState();
    const [branch, setBranch] = useState("All");
    const [course, setCourse] = useState("All");
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const startDateP = searchParams.get("startDate");
        const endDateP = searchParams.get("endDate");
        const branchP = searchParams.get("branch");
        if(startDateP === null){return;}
        const dateRangeP = [new Date(startDateP), new Date(endDateP)];
        setDateRange(dateRangeP);
        setBranch(branchP);
        updateReport(dateRangeP, branchP);
    }, [searchParams]);

    useEffect(() => {
        if(!dateRange || dateRange.length === 0){return;}
        const params = {startDate: dateRange[0], endDate: dateRange[1], branch: branch};
        navigate({pathname: '/examList', search: `?${createSearchParams(params)}`}, {replace: true});
    }, [dateRange, branch]);

    useEffect(() => {
        filterByCourse();
    }, [course, students]);

    const updateReport = async (dateRangeP, branchP) => {
        const startDate = moment(dateRangeP[0]).format("yyyy-MM-DD");
        const endDate = moment(dateRangeP[1]).format("yyyy-MM-DD");
        let studList = await fetchStudentByExamDate(startDate, endDate, branchP);
        console.log(studList);
        setStudents([...studList]);
    }

    const filterByCourse = () => {
        console.log(students);
        if(course === "All"){
            setFilteredStudents(students);
        }else{
            setFilteredStudents(students.filter((stud) => stud.course.map((c) => c.courseName).includes(course)));
        }
    }

    const handleOnlySaveMarks = async () => {
        const studsList = filteredStudents;
        console.log(studsList);
        const isSaved = await updateMultipleStudentPercent(studsList);
        if(isSaved){
            alert("Saved!");
        }else{
            alert("Error!");
        }
    }

    const handleSaveMarks = async () => {
        const studsList = filteredStudents;
        console.log(studsList);
        const isSaved = await updateMultipleStudentPercent(studsList);
        if(isSaved){
            alert("Saved... Downloading");
            // TODO: generate certificates
            const allElem = document.getElementsByClassName("certificate");
            // const element = document.getElementById("certificate");
            const pdfDoc = await PDFDocument.create();
            for(let i = 0; i < allElem.length; ++i){
                const canvas = await html2canvas(allElem[i]);
                const pngImage = await pdfDoc.embedPng(canvas.toDataURL("image/png"));

                const page1 = pdfDoc.addPage([2480, 3508]);
                page1.moveTo(0, 0);
                page1.drawImage(pngImage, {
                    width: 2480,
                    height: 3508
                });
            }
            
            const pdfBytes = await pdfDoc.save();
            const blob   = new Blob( [ pdfBytes ], { type: "application/pdf" } );
            const docUrl = URL.createObjectURL( blob );
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = docUrl;
            a.download = `Certificate-${moment(dateRange[0]).format("DD-MM-YYYY")}-${moment(dateRange[1]).format("DD-MM-YYYY")}.pdf`;
            a.click();
        }else{
            alert("Error!");
        }
    }

    const onStudentPercentChange = (index, i, v) => {
        const studsList = students;
        studsList[index].course[i].percent = v;
        setStudents([...studsList]);
    }

    const handlePrinted = async (regNum, cName, isAdd) => {
        const isSaved = await updateCertificatePrintedDate(regNum, cName, isAdd);
        if(isSaved){
            updateReport(dateRange, branch);
        }else{
            alert("Error!");
        }
    }

    const handleSingleDownload = async (regNum, cName) => {
        const studsList = filteredStudents.filter((stud) => stud.regNum === regNum);

        const isSaved = await updateMultipleStudentPercent(studsList);
        if(isSaved){
            alert("Saved... Downloading");
            // TODO: generate certificates
            const allElem = document.getElementsByClassName(cName);
            if(allElem.length === 0){
                alert("Error!");
                return;
            }
            // const element = document.getElementById("certificate");
            const pdfDoc = await PDFDocument.create();
            const canvas = await html2canvas(allElem[0]);
            const pngImage = await pdfDoc.embedPng(canvas.toDataURL("image/png"));

            const page1 = pdfDoc.addPage([2480, 3508]);
            page1.moveTo(0, 0);
            page1.drawImage(pngImage, {
                width: 2480,
                height: 3508
            });
            
            const pdfBytes = await pdfDoc.save();
            const blob   = new Blob( [ pdfBytes ], { type: "application/pdf" } );
            const docUrl = URL.createObjectURL( blob );
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = docUrl;
            a.download = `Certificate-${regNum}.pdf`;
            a.click();
        }else{
            alert("Error!");
        }
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>
                        Exam List {filteredStudents.length !== 0 && `(${filteredStudents.length})`}
                        <ActionBar>
                            <BasicButton onClick={handleOnlySaveMarks}>Save</BasicButton>
                            &nbsp;
                            <BasicButton onClick={handleSaveMarks}>Download</BasicButton>
                        </ActionBar>
                    </PageHeader>
                    <CustomInput label={"Date Range"} type="dateRange" value={dateRange} onChangeValue={(v) => {
                        setDateRange(v);
                    }}/>
                    <CustomInput label={"Branch"} type="dropdown" value={branch} onChangeValue={(v) => {
                        setBranch(v);
                    }} menuItems={branchesWithAll}/>
                    <CustomInput label={"Course"} type="dropdown" value={course} onChangeValue={(v) => {
                        setCourse(v);
                    }} menuItems={coursesListWithAll}/>
                    <ExamTable students={filteredStudents} onStudentPercentChange={onStudentPercentChange} handleSingleDownload={handleSingleDownload} handlePrinted={handlePrinted}/>
                    <HideContainer>
                        {
                            filteredStudents.map((stud) => {
                                return stud.course.map((c) => {
                                    return valid(c.percent) ? <CertificatePage data={stud} course={c}/> : <></>
                                })
                            })
                        }
                    </HideContainer>
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
}

export default ExamList;