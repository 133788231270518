import moment from "moment";
import React from "react";
import styled from "styled-components";
import { ClickableCell, TableCell, TableHeader } from "./styles";
import {categoryOfExpense} from "../utils/constants";
import { useNavigate } from "react-router-dom";

const TableGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 8px;
    overflow: hidden;
`;

const BranchName = styled.div`
    margin: 1rem 0;
    font-size: 1.2rem;
    font-weight: bold;
`;

const CategoryName = styled.div`

`;

const FeeTotal = styled.div`
    font-size: 1.3rem;
    font-weight: bold;
    width: 100%;
    text-align: right;
`;

const ExpensesReportTable = ({branchName, feeList, expenseList}) => {
    const navigate = useNavigate();

    const findTotal = () => {
        return expenseList.reduce((total, exp) => {
            return total + parseInt(exp.amount);
        }, 0);
    }

    const findFeeTotal = () => {
        return feeList.reduce((total, fee) => {
            return total + parseInt(fee.money);
        }, 0);
    }

    const findCategoryTotal = (cat) => {
        return expenseList.filter((exp) => exp.category === cat).reduce((total, exp) => {
            return total + parseInt(exp.amount);
        }, 0);
    }

    return (
        <>
            <BranchName>{branchName}</BranchName>

            {
                categoryOfExpense.map((cat) => {
                    return <>
                        <CategoryName>{cat}</CategoryName>
                        <TableGrid>
                            <TableHeader>Date</TableHeader>
                            <TableHeader>Amount ({findCategoryTotal(cat)})</TableHeader>
                            <TableHeader>Detail</TableHeader>
                            {
                                expenseList.filter((exp) => exp.category === cat).map((exp, index) => {
                                    return <>
                                        <TableCell>{moment(exp.date).format("DD/MM/YYYY")}</TableCell>
                                        <ClickableCell><TableCell onClick={() => {
                                            navigate(`/editExpense/${exp.uuid}`);
                                        }}>{exp.amount}</TableCell></ClickableCell>
                                        <TableCell>{exp.desc}</TableCell>
                                    </>
                                })
                            }
                        </TableGrid>
                    </>
                })
            }
            
            <FeeTotal>Total: ₹{findFeeTotal()} - {findTotal()} = ₹{findFeeTotal() - findTotal()}/-</FeeTotal>
        </>
    );
}

export default ExpensesReportTable;